import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { NumericFormat } from 'react-number-format';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import InvestmentPackageCard from "../../shared/components/investment-package-card";
import InvestmentProjectService from "../../shared/services/investment-project-service";
import PledgeProgressBar from '../../seller/components/pledge-progress-bar';
import AnswerFormDisplay from '../../shared/components/answer-form-display';
import { ImageFullSizeDisplay } from '../../shared/components/image-full-size-display';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconPicker from '../../shared/components/icon-picker';
import UNGoalsImagePicker from '../../shared/components/un-goals-image-picker';
import { DownloadResourceButton } from '../../shared/components/project-resources/download-resource-button';
import BuyerInvestmentProjectService from '../services/investment-project-service';
import { BuyerEnquiryButton } from '../../shared/components/enquiry-button';
import { InvestmentProjectDisplayNotes } from '../../shared/components/investment-project/investment-project-display-notes';
import { UserDetailsContext } from '../../shared/context/user-details';
import { SignUpDialog } from '../../shared/components/dialogs/sign-up';
import { ContentCopy, Download, MailOutline } from '@mui/icons-material';
import { LocationOn } from '@mui/icons-material';
import { EmailIcon, EmailShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, XIcon } from 'react-share';
import { SocialMediaShareComponent } from '../../shared/components/presentation/social-media-share';
import { DialogInvestmentProjectEnquiry } from '../../shared/components/dialogs/dialog-investment-project-enquiry';

const InvestmentDetails = () => {

  const investmentProjectService = new InvestmentProjectService();
  const buyerInvestmentProjectService = new BuyerInvestmentProjectService();
  const userDetailsContext = useContext(UserDetailsContext);

  const location = useLocation();
  const state = location.state;
  const project = state ? state.project : null;
  let navigate = useNavigate();

  const [projectDetails, setProjectDetails] = useState(project);
  const [projectImages, setProjectImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [projectSaved, setProjectSaved] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [signUpFormActive, setSignUpFormActive] = useState(false);
  const [findOutMoreActive, setFindOutMoreActive] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getInvestmentProject();
  }, []);

  useEffect(() => {
    if (userDetailsContext.userDetails?.name) {
      setAuthenticated(true);
    }
  }, [userDetailsContext.userDetails]);

  useEffect(() => {
    if (userDetailsContext.userDetails)
      window.analytics.page();
  }, []);

  const copyLinkClick = () => {
    navigator.clipboard.writeText(window.location.href);
    //setShareAlertOpen(true);
  };

  const getInvestmentProject = async () => {
    const path = window.location.pathname;
    const projectId = path.substring(path.lastIndexOf('/') + 1);

    investmentProjectService.getOneInvestmentProject(projectId)
      .then(response => {
        const dateAddedISO = new Date(response.created_at.replace(' ', 'T'));
        const dateAdded = dateAddedISO.toLocaleDateString('en-GB');
        const project = { ...response, dateAdded: dateAdded };
        setProjectImages(response.investment_project_images);
        setProjectDetails(project);
        setProjectSaved(response.isSaved);
        setIsLoading(false);
      })
      .catch(err => {
        navigate('/hub/invest');
      });
  };
  const toggleSignUpFormActive = () => {
    if (signUpFormActive) {
      getInvestmentProject();
    }
    setSignUpFormActive(!signUpFormActive);
  };

  const handleSaveFavorite = () => {
    buyerInvestmentProjectService.toggleFavorite(projectDetails.id)
      .then(() => {
        setProjectSaved(!projectSaved);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const enquirySuccess = () => {
    getInvestmentProject();
  };

  return (
    <>
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container alignItems="center" sx={{ mb: 2 }}>
          <Grid item xs={12} md={6} lg={6} alignItems="center">
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate(-1)}
              underline='hover'
              sx={{ ml: '-7px' }}
            >
              <Grid container direction="row" alignItems="center">
                <KeyboardArrowLeft />
                <Typography variant="button" display="block" className='removeUppercase'>
                  Back to search results
                </Typography>
              </Grid>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={6} key={uuidv4()}>
            <Stack direction="row" justifyContent="flex-end" alignItems={'center'}>
              <Tooltip title="Save as favourite">
                <IconButton
                  aria-label="favourite"
                  onClick={() => handleSaveFavorite()}
                >
                  {!projectSaved ? (<FavoriteBorderOutlinedIcon />) : (<FavoriteIcon />)}
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy link to clipboard">
                <IconButton aria-label="share" onClick={() => copyLinkClick()} style={{ marginLeft: 0 }}>
                  <ContentCopy />
                </IconButton>
              </Tooltip>
              <SocialMediaShareComponent />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} lg={8}>
            <Paper
              elevation={0}
              sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto'
              }}
            >

              {projectImages && <ImageFullSizeDisplay
                images={projectImages}
                canEdit={false}
                indicators={true}
                videoUrl={projectDetails?.video_url}
                useComparisonSlider={projectDetails?.use_slider_image}
              >
              </ImageFullSizeDisplay>}

              <Box>
                <Grid container spacing={2} sx={{ pl: '6px', pt: 4 }}>
                  <Grid item xs={12} md={9} lg={9}>
                    <Box>
                      <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                        {projectDetails?.title_text}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body" color="text.primary" sx={{ mb: '20px', whiteSpace: 'pre-wrap' }}>
                        {projectDetails?.subtitle_text}
                      </Typography>
                    </Box>
                    {projectDetails?.custom_region_tag &&
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600 }}>
                          <LocationOn color='primary' /> {projectDetails?.custom_region_tag}
                        </Typography>
                      </Box>
                    }
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <Typography variant="body" color="text.secondary" sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}>
                      Added on {projectDetails?.dateAdded}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
                    <Typography variant="subtitle1" color="text.primary" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                      {projectDetails?.description}
                    </Typography>

                    {projectDetails?.admin_display_notes && <Divider sx={{ mt: 6, mb: 6 }} />}

                    <InvestmentProjectDisplayNotes isAdmin={false} investmentProject={projectDetails} />

                    <Divider sx={{ mt: 6, mb: 6 }} />
                    {projectDetails?.un_goals &&
                      <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
                        <Typography variant="h6" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
                          Related UN Sustainable Development Goals (SDGs)
                        </Typography>
                        <Grid container spacing={2}>
                          {projectDetails?.un_goals?.split(',').map(goal => {
                            return <Grid item xs={6} md={3} lg={2}>
                              <UNGoalsImagePicker id={goal} />
                            </Grid>;
                          })}
                        </Grid>
                      </Grid>}
                    {/* {projectDetails?.un_goals && <Box sx={{ mb: 4 }}>
                      <Typography variant="h6" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
                        Project goals
                      </Typography>
                      <UnGoalsImagePicker ids={projectDetails?.un_goals}></UnGoalsImagePicker>
                    </Box>} */}

                    {projectDetails?.answer_forms?.find(form => form.answers?.length > 0) && <Typography variant="h6" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
                      About this project
                    </Typography>}

                    {projectDetails?.answer_forms?.filter(form => form.answers.length > 0).map((form, index) => {
                      return <Accordion
                        key={index}
                        expanded={expanded === `panel-${index}`}
                        onChange={handleChange(`panel-${index}`)}>

                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          sx={{ alignItems: 'center', justifyContent: 'center' }}

                        >
                          <List sx={{ p: 0 }}>
                            <ListItem sx={{ p: 0 }}>
                              <ListItemIcon>
                                <IconPicker icon="form" color='primary' />
                              </ListItemIcon>
                              <ListItemText primary={form.question_form?.form_name} />
                            </ListItem>
                          </List>
                        </AccordionSummary>
                        <AccordionDetails>
                          <AnswerFormDisplay key={index} suppliedAnswers={form.answers} useDisplayLabels={true} />
                        </AccordionDetails>
                      </Accordion>;
                    })}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={5}
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                //height: 'calc(100vh - 110px)',
                //height: '400px',
                width: '100%',
                position: projectDetails?.investment_packages.length === 0 && 'sticky',
                top: '90px',
                borderRadius: '10px'
              }}
            >
              <Grid container>
                <Grid item xs={12} md={6} lg={6}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    {projectDetails?.title_text}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Chip
                    label={projectDetails?.shallow_status?.status_name}
                    variant="outlined"
                    sx={{
                      justifyContent: "flex-end",
                      ml: "auto",
                      float: "right",
                      color: projectDetails?.shallow_status?.color || "primary",
                      borderColor: projectDetails?.shallow_status?.color || "primary"
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                  <Typography variant="p" color="text.secondary">
                    {projectDetails?.subtitle_text}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <PledgeProgressBar value={projectDetails ? (projectDetails?.amount_pledged / projectDetails?.investment_target) * 100 : 0} />
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{ mt: 1 }}>
                  <Typography variant="h3" color="primary" sx={{ fontWeight: 600 }}>
                    <NumericFormat value={projectDetails?.amount_pledged} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="body2" color="text.secondary">
                    Raised of <NumericFormat value={projectDetails?.investment_target || 0} displayType={'text'} thousandSeparator={true} prefix={'£'} /> target
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 3, mb: 3 }} />
              <Stack
                spacing={2}
              >

                {projectDetails && projectDetails?.resources?.length > 0 && !authenticated && <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  className='removeUppercase'
                  startIcon={<Download />}
                  onClick={() => toggleSignUpFormActive()}
                  sx={{ width: '100%' }}>
                  Download Brochure
                </Button>}

                {signUpFormActive && <SignUpDialog open={signUpFormActive} closeDialog={toggleSignUpFormActive} role='BUYER' />}

                {projectDetails && projectDetails?.resources?.length > 0 && authenticated && <DownloadResourceButton buttonCopy={'Download Brochure'} route={projectDetails.resources[0].location} />}
                {/* {authenticated && projectDetails && <BuyerEnquiryButton
                  projectId={projectDetails.id}
                  enquiryId={projectDetails.enquiryId}
                  projectType={'INVESTMENT_PROJECT'}
                  onSuccess={enquirySuccess}
                  userRole={'buyer'}
                />} */}
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  className='removeUppercase'
                  startIcon={<MailOutline />}
                  onClick={() => setFindOutMoreActive(true)}
                >

                  Find out more
                </Button>

                {findOutMoreActive && <DialogInvestmentProjectEnquiry
                  active={findOutMoreActive}
                  investmentProjectId={projectDetails.id}
                  onCancel={() => setFindOutMoreActive(false)}
                  onSubmit={() => setFindOutMoreActive(false)}
                />}


                {authenticated && <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disableElevation
                  className='removeUppercase'
                  startIcon={!projectSaved ? (<FavoriteBorderOutlinedIcon />) : (<FavoriteIcon />)}
                  onClick={() => handleSaveFavorite()}
                >
                  {!projectSaved ? ('Save as favourite') : ('Favourite')}
                </Button>}

                {/* {!authenticated && <Stack
                  spacing={2}
                >
                  {!projectDetails?.enquiryId && <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disableElevation
                    className='removeUppercase'
                    startIcon={<MailOutline />}
                    onClick={() => toggleSignUpFormActive()}
                    disabled={authenticated}
                  >
                    Contact Seller
                  </Button>}


                </Stack>} */}

                {signUpFormActive && <SignUpDialog open={signUpFormActive} closeDialog={toggleSignUpFormActive} role='BUYER' />}
              </Stack>
            </Paper>
            {projectDetails?.investment_packages.length > 0 && (
              <>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 6, mb: 2, ml: 1 }}>
                  <Typography variant="h6" color="text.primary">
                    <b>Donation packages</b>
                  </Typography>
                  <Typography variant="p" color="text.secondary">
                    Choose from one of the donation packages for maximum impact.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  {projectDetails?.investment_packages.map((investment_package, index) => (
                    <InvestmentPackageCard
                      investment_package={investment_package}
                      key={index}
                    />
                  )
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default InvestmentDetails;