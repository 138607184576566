import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import { pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { PiePlot, ResponsiveChartContainer } from '@mui/x-charts';
import { Download } from '@mui/icons-material';

export const GraphDonutChart = ({
  title,
  dataset,
  series,
  xAxisDataKey,
  additionalSeriesIgnoreKeys,
  valueFormatStringValue,
  customValueString,
  allowDownload
}) => {

  // const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('sm'));
  const colors = [
    '#5c8024', // Subdued green  - 800
    '#c9da9e', // Pastel green - 200
    '#a3c15a', // Bright green - 400
    '#70942c', // Earthy green - 700
    '#dee9c4', // Pale green - 100
    '#84a736', // Fresh and lively - 600
    '#b3cb78', // Soft green - 300
    '#93b73d', // Bright green - 500
    '#f2f6e7', // Very light green - 50
    '#3a5f13', // Darker shade - 900
  ];


  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = e => {
    e.preventDefault();
    let headers = [[xAxisDataKey, customValueString]];
    let searchesCsv = dataset.reduce((acc, datum) => {
      // const { unit_type, value } = datum;
      acc.push([datum[xAxisDataKey].split(',').join(' - '), `${datum.value?.toFixed(2)} ${valueFormatStringValue ? valueFormatStringValue : ''}`].join(','));
      return acc;
    }, []);
    downloadFile({
      data: [...headers, ...searchesCsv].join('\n'),
      fileName: `${title?.toLocaleLowerCase().split(' ').join('_')}.csv`,
      fileType: 'text/csv',
    });
  };

  return (
    <Paper variant='outlined' sx={{ p: 4, height: '100%' }}>
      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant='subtitle1' sx={{ flex: 1 }}>
          <b>{title}</b>
        </Typography>
        {allowDownload && <IconButton size='medium' onClick={exportToCsv}>
          <Download></Download>
        </IconButton>}

      </Stack>
      <Box sx={{ width: '100%' }}>
        <ResponsiveChartContainer
          height={300}
          series={[
            {
              arcLabel: (item) => item.formattedValue,
              arcLabelMinAngle: 30,
              arcLabelRadius: 65,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 40, additionalRadius: -15, color: 'gray' },
              data: dataset.map(datum => {
                return {
                  value: datum.value.toString(),
                  label: datum.unit_type
                };
              }),
              type: 'pie',
              innerRadius: 40,
              outerRadius: 100,
              paddingAngle: 2,
              cornerRadius: 5,
              valueFormatter: (value) => `${value.value.toFixed(2)}%`,
            }
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
            },
          }}
          colors={colors}
        > <PiePlot />
        </ResponsiveChartContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          {dataset.map((entry, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', gap: 0.5 }}>
              <Box sx={{
                width: 20, height: 20, borderRadius: '5px',
                backgroundColor: colors[index]
              }}></Box>
              <Typography sx={{ mr: 2 }}>{entry.unit_type}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};