import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import './app.css';
import WebsitePageLayout from './shared/root-components/layouts/website-page-layout';
import NoNavbarPageLayout from './shared/root-components/layouts/no-navbar';
import ConfigurationPageLayout from './shared/root-components/layouts/configuration-page-layout';
import AdminPageLayout from './shared/root-components/layouts/admin-page-layout';
import { UserInformationProvider } from './shared/context/user-information-provider';
import AppPageLayout from './shared/root-components/layouts/app-page-layout';
import Unprotected from './unprotected';

// Shared Pages
import HomePage from "./shared/pages/home";
import Verification from "./shared/pages/auth/verification";
import ResetPassword from "./shared/pages/auth/reset-password";
import Calculator from "./shared/pages/tools/calculator";
import RequireAuth from './shared/guards/require-auth';
import AdminRoute from './shared/guards/admin-route';
import SuperAdminRoute from './shared/guards/super-admin-route';
import IsHub from './shared/guards/hub-check';
import { HubDetailsProvider } from './shared/context/hub-details';
import { UserDetailsProvider } from './shared/context/user-details';

// Seller Pages
import ProjectsPage from "./seller/pages/projects";
import EnquiriesPage from "./seller/pages/enquiries";
import SalesPage from "./seller/pages/sales";
import ProjectDetailsSurveyor from "./seller/pages/project-details";
import HMMPchecklistPage from "./seller/pages/hmmp-checklist";
import Dashboard from "./seller/pages/dashboard";
import CreateProject from './seller/pages/create-project';
// import EnquiryDetailSeller from './seller/pages/enquiry-detail';
import CreateArticle from './admin/pages/articles/create-article';
import CreateInvestmentProject from './seller/pages/create-investment-project';

// Buyer Pages
import ProjectDetails from "./buyer/pages/project-details";
import BuyerHomePage from "./buyer/pages/home";
import Enquiries from "./buyer/pages/enquiries";
import SavedProjects from "./buyer/pages/saved-projects";
import Learning from "./buyer/pages/learning-hub/learning";
import InvestInNature from "./buyer/pages/invest-in-nature";
import NutrientCredits from "./buyer/pages/nutrient-credits";
import InvestmentDetails from "./buyer/pages/investment-project-details";
import Payment from './buyer/pages/payment';
import EnquiryDetail from './buyer/pages/enquiry-detail';
import HubPage from './buyer/pages/hub';
import HubHomePage from './buyer/pages/hub-home';

// Admin Pages
import Approvals from './admin/pages/approvals';
import Branding from './admin/pages/configuration/branding';
import CreateCustomForm from './admin/components/config/custom-forms/create-custom-form';
import ViewCustomForm from './admin/pages/question-form/view-custom-form';
import Team from './admin/pages/configuration/team';
import Modules from './admin/pages/configuration/modules';
import Forms from './admin/pages/configuration/forms';
import InvestmentProjectsPage from './seller/pages/investment-projects';
import InvestmentProjectDetails from './seller/pages/investment-project-details';
import ConfigInvestModule from './admin/pages/configuration/modules/invest';
import ConfigMarketplaceModule from './admin/pages/configuration/modules/marketplace';
import ConfigLearningModule from './admin/pages/configuration/modules/learning';
import AdminInvestmentProjectPage from './admin/pages/admin-investment-project';
import AdminBngProjectPage from './admin/pages/admin-bng-project';
import AdminDashboard from './admin/pages/admin-dashboard';
import EditCustomForm from './admin/components/config/custom-forms/edit-custom-form';

// Super Admin Pages
import SuperAdminDashboard from './admin/pages/super-admin/dashboard';
import CreateHub from './admin/pages/super-admin/create-hub';
import Analytics from './admin/pages/super-admin/analytics';
import AdminProjectsPage from './admin/pages/projects';
import AdminInvestmentProjectsPage from './admin/pages/investment-projects';
import UserSignUpQuestions from './shared/pages/sign-up-questions';
import AdminUserDetail from './admin/pages/user-detail';


// Plus Pages
import DemoHubLandingPage from './seller/demo/demo-hub-landing-page';
import AdminUsers from './admin/pages/users';
import CustomFormFlow from './admin/pages/configuration/form-flow';
import CreateFormFlow from './admin/pages/form-flow/create-form-flow';
import ViewCustomFormFlow from './admin/pages/form-flow/view-custom-form-flow';
import EditFormFlow from './admin/pages/form-flow/edit-form-flow';
import AdminArticleDetail from './admin/pages/articles/article-detail';
import AdminArticleList from './admin/pages/articles/article-list';
import ArticleDetails from './buyer/pages/learning-hub/article-details';
import { ContactUsPage } from './shared/pages/contact-us';
import { AboutPage } from './shared/pages/cornwall/about';
import GaiaPlusProjectDetails from './plus/pages/project-detail';
import { CreateGaiaPlusProjectPage } from './admin/pages/plus/create-new-gaia-plus-project';
import { GaiaPlusProjectsPageAdmin } from './admin/pages/plus/gaia-plus-projects-page';
import { GaiaPlusProjectDetailPageAdmin } from './admin/pages/plus/gaia-plus-project-detail-page';
import { OurOfferPage } from './shared/pages/cornwall/our-offer';
import { AddGaiaPlusTask } from './admin/pages/plus/add-task';
import { GaiaPlusTaskList } from './admin/pages/plus/task-list';
import { GaiaPlusProjectDetailPageEcologist } from './ecologists/pages/gaia-plus-project-detail-page-ecologist';
import ScrollTopService from './shared/components/presentation/scroll-top-service';
import { Box } from '@mui/material';
import { AuthenticationPage } from './shared/components/auth/authentication-page';
import { FAQsPage } from './shared/pages/cornwall/faqs';
import { NewsPage } from './shared/pages/cornwall/news';
import { BackgroundPage } from './shared/pages/cornwall/about/background';
import { GovernancePage } from './shared/pages/cornwall/about/governance';
import { WhyPage } from './shared/pages/cornwall/about/why-invest';
import { WhoPage } from './shared/pages/cornwall/about/who-are-we';
import { WhatPage } from './shared/pages/cornwall/about/what-is-linc';
import InvestmentProjectMap from './buyer/pages/investment-project-map';
import { AdminEnquiries } from './admin/pages/admin-enquiries';
import { AdminEnquiryDetail } from './admin/pages/admin-enquiry-detail';
import { StaticPage } from './shared/pages/static-page';
import { StaticPageList } from './admin/pages/configuration/static-pages/static-page-list';
import { StaticPageDetail } from './admin/pages/configuration/static-pages/static-page-detail';
import { CreateStaticPage } from './admin/pages/configuration/static-pages/create-static-page';
import { SiteEnquiryFormPage } from './buyer/pages/site-enquiry-form-page';
import { SiteEnquiryFormList } from './admin/pages/site-enquiry-form-list';
import { SiteEnquiryFormDetail } from './admin/pages/site-enquiry-detail';
import { NewsAndEvents } from './admin/pages/configuration/news-and-events';
import MarketplaceSearches from './admin/pages/super-admin/marketplace-searches';
import { OrganisationSettingsPage } from './seller/pages/organisation-settings/organisation-settings';
import AdminProductAddOn from './admin/pages/super-admin/product-add-on';
import AdminProductAddOnDetail from './admin/pages/super-admin/product-add-on-detail';
import { AnalyticsPage } from './shared/pages/analytics-dashboard/analytics-dashboard';

export const App = () => {

  return (<>
    <HubDetailsProvider>
      <UserDetailsProvider>
        <UserInformationProvider>
          <Box sx={{ minHeight: '80px' }}>
            <ScrollTopService />
            <Routes>
              <Route path="/" element={<HomePage />} />

              <Route element={<RequireAuth noRoleRequired />}>
                {/* No routes currently */}
              </Route>

              <Route element={<AdminPageLayout><Outlet /></AdminPageLayout>}>
                {/* Admin routes */}
                <Route element={<ConfigurationPageLayout><Outlet /></ConfigurationPageLayout>}>
                  <Route path="/admin" element={<AdminRoute isAdmin />}>
                    <Route path="/admin/custom-forms/create" element={<CreateCustomForm roles={['Admin']} />} />
                    <Route path="/admin/custom-forms/:formId" element={<ViewCustomForm roles={['Admin']} />} />
                    <Route path="/admin/custom-forms/:formId/edit" element={<EditCustomForm roles={['Admin']} />} />
                    <Route path="/admin/form-flow/:formFlowId" element={<ViewCustomFormFlow roles={['Admin']} />} />
                    <Route path="/admin/configuration/branding" element={<Branding roles={['Admin']} />} />
                    <Route path="/admin/configuration/team" element={<Team roles={['Admin']} />} />
                    <Route path="/admin/configuration/modules" element={<Modules roles={['Admin']} />} />
                    <Route path="/admin/configuration/forms" element={<Forms roles={['Admin']} />} />
                    <Route path="/admin/configuration/form-flow" element={<CustomFormFlow roles={['Admin']} />} />
                    <Route path="/admin/configuration/form-flow/create/:formableObjectId" element={<CreateFormFlow roles={['Admin']} />} />
                    <Route path="/admin/configuration/form-flow/edit/:formFlowId" element={<EditFormFlow roles={['Admin']} />} />
                    <Route path="/admin/configuration/modules/invest" element={<ConfigInvestModule roles={['Admin']} />} />
                    <Route path="/admin/configuration/modules/learning" element={<ConfigLearningModule roles={['Admin']} />} />
                    <Route path="/admin/configuration/modules/marketplace" element={<ConfigMarketplaceModule roles={['Admin']} />} />
                    <Route path="/admin/configuration/static-pages" element={<StaticPageList roles={['Admin']} />} />
                    <Route path="/admin/configuration/static-pages/create" element={<CreateStaticPage roles={['Admin']} />} />
                    <Route path="/admin/configuration/static-pages/:slug" element={<StaticPageDetail roles={['Admin']} />} />
                    <Route path="/admin/configuration/news-and-events" element={<NewsAndEvents roles={['Admin']} />} />
                  </Route>
                </Route>

                {/* Admin routes without the configuration layout */}
                <Route path="/admin" element={<AdminRoute isAdmin />}>
                  <Route path="/admin/dashboard" element={<AdminDashboard roles={['Admin']} />} />
                  <Route path="/admin/approvals" element={<Approvals roles={['Admin']} />} />
                  <Route path="/admin/investment-project/:id" element={<AdminInvestmentProjectPage roles={['Admin']} />} />
                  <Route path="/admin/bng-project/:id" element={<AdminBngProjectPage roles={['Admin']} />} />
                  <Route path="/admin/articles" element={<AdminArticleList roles={['Admin']} />} />
                  <Route path="/admin/articles/new" element={<CreateArticle roles={['Admin']} />} />
                  <Route path="/admin/articles/:id" element={<AdminArticleDetail roles={['Admin']} />} />
                  <Route path="/admin/articles/edit/:id" element={<CreateArticle roles={['Admin']} />} />
                  <Route path="/admin/projects" element={<AdminProjectsPage roles={['Admin']} />} />
                  <Route path="/admin/investment-projects" element={<AdminInvestmentProjectsPage roles={['Admin']} />} />
                  <Route path="/admin/users" element={<AdminUsers roles={['Admin']} />} />
                  <Route path="/admin/users/:id" element={<AdminUserDetail roles={['Admin']} />} />
                  <Route path="/admin/gaia-plus/create" element={<CreateGaiaPlusProjectPage roles={['Admin']} />} />
                  <Route path="/admin/gaia-plus/all-projects" element={<GaiaPlusProjectsPageAdmin roles={['Admin']} />} />
                  <Route path="/admin/gaia-plus/tasks" element={<GaiaPlusTaskList roles={['Admin']} />} />
                  <Route path="/admin/gaia-plus/add-task" element={<AddGaiaPlusTask roles={['Admin']} />} />
                  <Route path="/admin/gaia-plus/project/:id" element={<GaiaPlusProjectDetailPageAdmin roles={['Admin']} />} />
                  <Route path="/admin/enquiries" element={<AdminEnquiries roles={['Admin']} />} />
                  <Route path="/admin/enquiry/:enquiryId" element={<AdminEnquiryDetail roles={['Admin']} />} />
                  <Route path="/admin/site-enquiries" element={<SiteEnquiryFormList roles={['Admin']} />} />
                  <Route path="/admin/site-enquiry/:id" element={<SiteEnquiryFormDetail roles={['Admin']} />} />
                </Route>

                {/* Super admin routes */}
                <Route path="/super-admin" element={<SuperAdminRoute isSuperAdmin />}>
                  <Route path="/super-admin/dashboard" element={<SuperAdminDashboard roles={['SuperAdmin']} />} />
                  <Route path="/super-admin/create-hub" element={<CreateHub roles={['SuperAdmin']} />} />
                  <Route path="/super-admin/analytics" element={<Analytics roles={['SuperAdmin']} />} />
                  <Route path="/super-admin/marketplace-searches" element={<MarketplaceSearches roles={['SuperAdmin']} />} />
                  <Route path="/super-admin/product-add-on/:code" element={<AdminProductAddOnDetail roles={['SuperAdmin']} />} />
                  <Route path="/super-admin/product-add-ons" element={<AdminProductAddOn roles={['SuperAdmin']} />} />
                </Route>
              </Route>


              <Route element={<AppPageLayout><Outlet /></AppPageLayout>}>
                {/* Seller routes */}
                <Route element={<RequireAuth isSeller />}>
                  <Route path="/dashboard" element={<Dashboard roles={['Seller']} />} />
                  <Route path="/projects/new" element={<CreateProject roles={['Seller']} />} />
                  <Route path="/projects" element={<ProjectsPage roles={['Seller']} />} />
                  <Route path="/project/:projectId" element={<ProjectDetailsSurveyor roles={['Seller']} />} />
                  <Route path="/deals" element={<EnquiriesPage roles={['Seller']} />} />
                  <Route path="/seller/enquiry/:enquiryId" element={<EnquiryDetail roles={['Seller']} />} />
                  <Route path="/sales" element={<SalesPage roles={['Seller']} />} />
                  <Route path="/investment-project/questions/:investmentProjectId/:answerFormId" element={<CreateInvestmentProject roles={['Seller']} />} />
                  <Route path="/investment-project/questions/:investmentProjectId" element={<CreateInvestmentProject roles={['Seller']} />} />
                  <Route path="/project/questions/:projectId/:answerFormId" element={<CreateProject roles={['Seller']} />} />

                  <Route path="/project/questions/:projectId" element={<CreateProject roles={['Seller']} />} />
                  <Route path="/investment-project/new" element={<CreateInvestmentProject roles={['Seller']} />} />
                  <Route path="/investment-projects" element={<InvestmentProjectsPage roles={['Seller']} />} />
                  <Route path="/investment-project/:investmentProjectId" element={<InvestmentProjectDetails roles={['Seller']} />} />
                  <Route path="/hmmp-checklist" element={<HMMPchecklistPage roles={['Seller']} />} />
                  <Route path="/user-sign-up-questions" element={<UserSignUpQuestions roles={['Seller']} />} />
                  <Route path="/ecologist/gaia-plus/project/:id" element={<GaiaPlusProjectDetailPageEcologist roles={['Seller']} />} />

                  <Route path="/settings/organisation" element={<OrganisationSettingsPage roles={['Seller']} />} />

                  {/* <Route path="/demo/set-up-hub" element={<DemoHubLandingPage roles={['Seller']} />} /> */}
                </Route>
              </Route>

              {/* Buyer routes */}
              <Route element={<WebsitePageLayout><Outlet /></WebsitePageLayout>}>
                <Route element={<RequireAuth isBuyer />}>
                  <Route path="/enquiries" element={<Enquiries roles={['Buyer']} />} />
                  <Route path="/buyer/enquiry/:enquiryId" element={<EnquiryDetail roles={['Buyer']} />} />
                  <Route path="/saved" element={<SavedProjects roles={['Buyer']} />} />
                  <Route path="/payment" element={<Payment roles={['Buyer']} />} />
                  <Route path="/upgrade" element={<Payment roles={['Buyer']} />} />
                </Route>
              </Route>

              {/* Plus routes */}
              <Route element={<WebsitePageLayout><Outlet /></WebsitePageLayout>}>
                <Route element={<RequireAuth isSeller />}>
                  <Route path="/gaia-plus/:id" element={<GaiaPlusProjectDetails />} />
                  <Route path="/add-on/analytics" element={<AnalyticsPage />} />
                </Route>
              </Route>

              {/* Hub routes */}
              <Route element={<WebsitePageLayout><Outlet /></WebsitePageLayout>}>
                <Route element={<IsHub />}>
                  <Route path="/hub/projects/:projectId" element={<ProjectDetails roles={['Buyer']} />} />
                  <Route path="/hub/marketplace" element={<BuyerHomePage roles={['Buyer']} />} />
                  <Route path="/hub/seller" element={<HubPage roles={['Buyer']} />} />
                  <Route path="/hub/buyer" element={<HubPage roles={['Buyer']} />} />
                  <Route path="/hub" element={<HubPage roles={['Buyer']} />} />
                  <Route path="/hub/home" element={<HubHomePage roles={['Buyer']} />} />
                  <Route path="/hub/learning" element={<Learning roles={['Buyer']} />} />
                  <Route path="/hub/learning/group/:groupCode" element={<Learning roles={['Buyer']} />} />
                  <Route path="/hub/learning/:id" element={<ArticleDetails roles={['Buyer']} />} />
                  <Route path="/hub/invest" element={<InvestInNature roles={['Buyer']} />} />
                  <Route path="/hub/invest/map" element={<InvestmentProjectMap roles={['Buyer']} />} />
                  <Route path="/hub/invest/:investmentId" element={<InvestmentDetails roles={['Buyer']} />} />
                  <Route path="/hub/nutrient-credits" element={<NutrientCredits roles={['Buyer']} />} />
                  <Route path="/hub/contact-us" element={<ContactUsPage roles={['Buyer']} />} />
                  <Route path="/hub/about" element={<AboutPage roles={['Buyer']} />} />
                  <Route path="/hub/our-offer" element={<OurOfferPage roles={['Buyer']} />} />
                  <Route path="/hub/faqs" element={<FAQsPage roles={['Buyer']} />} />
                  <Route path="/hub/news" element={<NewsPage roles={['Buyer']} />} />
                  <Route path="/hub/background" element={<BackgroundPage roles={['Buyer']} />} />
                  <Route path="/hub/governance" element={<GovernancePage roles={['Buyer']} />} />
                  <Route path="/hub/what-is-linc" element={<WhatPage roles={['Buyer']} />} />
                  <Route path="/hub/who-are-we" element={<WhoPage roles={['Buyer']} />} />
                  <Route path="/hub/why-invest" element={<WhyPage roles={['Buyer']} />} />
                  <Route path="/hub/pages/:slug" element={<StaticPage roles={['Buyer']} />} />
                  <Route path="/hub/site-enquiry/:pageType" element={<SiteEnquiryFormPage roles={['Buyer']} />} />
                </Route>
              </Route>

              {/* Unprotected routes */}
              <Route element={<NoNavbarPageLayout><Outlet /></NoNavbarPageLayout>}>

                <Route element={<RequireAuth isSeller />}>
                  <Route path="/demo/set-up-hub" element={<DemoHubLandingPage roles={['Seller']} />} />
                </Route>
                <Route element={<Unprotected />}>
                  <Route path="/login" element={<AuthenticationPage isLogin={true} />} />
                  <Route path="/signup" element={<AuthenticationPage />} />
                  <Route path="/verification" element={<Verification />} />
                  <Route path="/reset" element={<ResetPassword />} />
                </Route>
                <Route element={<WebsitePageLayout><Outlet /></WebsitePageLayout>}>
                  <Route path="/calculator" element={<Calculator />} />
                </Route>
              </Route>
            </Routes>
          </Box>
        </UserInformationProvider>
      </UserDetailsProvider>
    </HubDetailsProvider>
  </>
  );
};

export default App;