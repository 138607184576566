import { useContext, useEffect, useState } from 'react';
import { nationalCharacterAreasAutocomplete } from '../../shared/data/national-character-areas';
import { Autocomplete, TextField } from '@mui/material';
import { HubDetailsContext } from '../../shared/context/hub-details';
import * as Habitats from '../../shared/data/habitats';
import HubCustomListService from '../../shared/services/hub-custom-list-service';

let unitTypes = [
  {
    label: 'Area',
    filterName: 'unitType',
    group_id: 3,
    group: 'Habitat Type'
  },
  {
    label: 'Hedgerow',
    filterName: 'unitType',
    group_id: 3,
    group: 'Habitat Type'
  },
  {
    label: 'Watercourse',
    filterName: 'unitType',
    group_id: 3,
    group: 'Habitat Type'
  }
];

const BuyerSearch = (props) => {

  let statuses;
  const lpas = props.lpas?.map(lpa => ({ label: lpa.lpa_name, filterName: 'lpa', group: 'Local Planning Authority' }));
  const ncas = nationalCharacterAreasAutocomplete.map(nca => ({ ...nca, filterName: 'nca', group: 'National Character Area' }));
  let broadHabitats = Habitats.broadHabitatsAutocomplete.map(broadHabitat => ({ ...broadHabitat, filterName: 'broadHabitat', group: 'Broad Habitat' }));
  const [optionsArray, setOptionsArray] = useState([]);
  let id = 1;

  const hub = useContext(HubDetailsContext);
  const hubCustomListService = new HubCustomListService();

  const [inputLabel, setInputLabel] = useState("Enter a location or habitat type");

  useEffect(() => {
    statuses = props.allowedBngShallowStatuses ? props.allowedBngShallowStatuses?.map(status => ({ label: status.status_name, filterName: 'shallow_status', group: 'Project status' })) : [];
    hubCustomListService.getCustomRegionList(true)
      .then(response => {
        if (props.hubHasBngDisabled) {
          unitTypes = [];
          broadHabitats = [];
          setInputLabel("Enter a location or project status");
        }
        const newRegions = response.data.map(region => ({ label: region.value, filterName: 'region', group: 'Region', group_id: 5 }));
        if (response.custom_list_retreived) {
          setOptionsArray([...newRegions, ...unitTypes, ...broadHabitats, ...statuses].map(option => ({ ...option, key: id++ })));
        } else {
          setOptionsArray([...newRegions, ...unitTypes, ...lpas, ...ncas, ...broadHabitats, ...statuses].map(option => ({ ...option, key: id++ })));
        }
      });
  }, [hub, props.allowedBngShallowStatuses]);

  return (
    <>
      {lpas && <Autocomplete
        disablePortal
        id="buyerSearch"
        freeSolo
        blurOnSelect={true}
        clearOnEscape
        autoHighlight
        options={optionsArray}
        groupBy={(option) => option.group}
        renderInput={(params) => <TextField {...params} label={inputLabel} />}
        onChange={(e, option) => props.handleSetFilter(option?.filterName, option?.label)}
        onInputChange={(e, value) => props.setSearchInputValue(value)}
        inputValue={props.searchInputValue}
        value={props.searchInputValue}
      />}
    </>

  );
};

export default BuyerSearch;