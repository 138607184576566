import httpClient from "../http/http-client";

export default class AnalyticsService {
  http;

  constructor() {
    this.http = httpClient;
  }

  async getAnalytics(queryString) {
    return this.http.get(`site-user/analytics/bng${queryString}`)
      .then(response => {
        return response.data;
      });
  }

  async getSearchAnalytics(queryString) {
    return this.http.get(`site-user/analytics/search${queryString}`)
      .then(response => {
        return response.data;
      });
  }
}