import { Autocomplete, Button, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import AdminInvestmentProjectService from '../../../admin/services/admin-investment-project';
import { ungoalsAutocomplete } from '../../data/un-goals';
import { useEffect, useState } from 'react';
import { Edit, Save } from '@mui/icons-material';
import UnGoalsImagePicker from '../un-goals-image-picker';
import AdminProjectService from '../../../admin/services/admin-project-service';

export const UnGoalsDisplay = ({ investmentProject, isAdmin, getInvestmentProject, project, getProject }) => {

  const adminInvestmentProjectService = new AdminInvestmentProjectService();
  const adminProjectService = new AdminProjectService();

  const [isEditing, setIsEditing] = useState(false);
  const [unGoalsArray, setUnGoalsArray] = useState([]);

  const toggleEdit = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      const goals = unGoalsArray.map(goal => goal.id).toString()
      if (investmentProject) {
        adminInvestmentProjectService.editInvestmentProject(investmentProject.id, { un_goals: goals })
          .then(() => {
            setIsEditing(false);
            getInvestmentProject();
          })
      } else if (project) {
        adminProjectService.editProject(project.p_id, { un_goals: goals })
          .then(() => {
            setIsEditing(false);
            getProject()
          })
      }
      
    }
  }

  const handleUpdateGoals = (goals) => {
    setUnGoalsArray(goals);
  }

  useEffect(() => {
    let goals;
    if (investmentProject?.un_goals) {
      goals = investmentProject?.un_goals
    } else if (project?.un_goals) {
      goals = project?.un_goals
    }
    if (goals) {
      const goals_array = goals.split(',');
      let new_goals_array = [];
      goals_array?.forEach(goal => {
        const goalInfo = ungoalsAutocomplete.filter((obj) => obj.id === Number(goal));
        return new_goals_array?.push(goalInfo[0]);
      })
      setUnGoalsArray(new_goals_array);
    }

  }, [investmentProject])

  return (<>

    {isAdmin && <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 2 }}>
      <Typography variant="h6" color="text.primary">
        <b>Related UN SDGs:</b>
      </Typography>
      <Button
        variant={isEditing ? 'contained' : 'outlined'}
        color={isEditing ? 'primary' : 'info'}
        size="large"
        disableElevation
        className='removeUppercase'
        startIcon={isEditing ? <Save /> : <Edit />}
        onClick={() => toggleEdit()}
      //disabled={!canSendEnquiry}
      >
        {isEditing ? 'Save goals' : 'Edit'}
      </Button>
    </Stack>}
    {isEditing && <FormControl fullWidth margin='normal' sx={{ mb: 4 }}>
      <Autocomplete
        disablePortal
        id="unGoals"
        value={unGoalsArray}
        options={ungoalsAutocomplete}
        renderInput={(params) => <TextField {...params} label="UN Sustainable Development Goals" />}
        multiple
        //value={searchFilters['region']}
        getOptionLabel={(option) => (option ? option.label : "")}
        onChange={(e, option, reason) => handleUpdateGoals(option)}
      />
    </FormControl>}

    {unGoalsArray.length === 0 && !isEditing && <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
      <b><i>No UN SDGs added</i></b>
    </Typography>}
    {unGoalsArray.length > 0 && <>
      <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 1 }}>
        {unGoalsArray.length > 0 && unGoalsArray?.map((goal, index) => {
          return <Grid item xs={6} md={3} lg={2} key={index}>
            <UnGoalsImagePicker id={goal.id} />
          </Grid>
        })}
      </Stack>
    </>
    }
  </>)

}