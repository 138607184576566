import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import { nationalCharacterAreasAutocomplete } from '../../../shared/data/national-character-areas';
import LocalPlanningAuthorityService from '../../../shared/services/local-planning-authority.service';
import { useEffect, useState } from 'react';
import UnitProjectDataInputForm from '../unit-project-input-data-form';

export const EditProjectDialog = (
  props
) => {

  const localPlanningAuthorityService = LocalPlanningAuthorityService.getInstance();
  const [lpas, setLpas] = useState();

  const handleUpdateUnitProject = (event, isCheckbox) => {
    const unitProject = props.updatedProjectDetails.unit_project;
    if (isCheckbox) {
      unitProject.hide_price = event.target.checked;
      props.setUpdatedProjectDetails({ ...props.updatedProjectDetails, unit_project: unitProject });
    } else {
      unitProject[event.target.name] = event.target.value;
      props.setUpdatedProjectDetails({ ...props.updatedProjectDetails, unit_project: unitProject });
    }
  };

  useEffect(() => {
    localPlanningAuthorityService.getLpas()
      .then(response => {
        setLpas(response);
      });
  }, []);
  return (
    <Dialog open={props.editFormActive} onClose={props.handleSoldClick} maxWidth='md' fullWidth>
      <DialogTitle sx={{ p: 4, pb: 2, }}>Edit Project Information</DialogTitle>
      <DialogContent sx={{ pt: 0, p: 4 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label="Title"
              id="summary"
              value={props.updatedProjectDetails.p_summary}
              onChange={e => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_summary: e.target.value
              })}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label="Description"
              id="additionalInfo"
              multiline
              rows={6}
              value={props.updatedProjectDetails.p_description}
              onChange={e => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_description: e.target.value
              })}
            />
          </FormControl>
          {lpas && <FormControl fullWidth margin='normal'>
            <Autocomplete
              disablePortal
              id="lpa"
              options={lpas}
              getOptionLabel={(option) => option.lpa_name}
              // inputValue={props.updatedProjectDetails.p_local_planning_authority}
              value={lpas?.find(lpa => lpa.lpa_name === props.updatedProjectDetails.p_local_planning_authority)}
              renderInput={(params) => <TextField {...params} label="Local Planning Authority" />}
              // onInputChange={(e, value) => props.setUpdatedProjectDetails({
              //   ...props.updatedProjectDetails,
              //   p_local_planning_authority: value.lpa_name
              // })}
              onChange={(e, value) => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_local_planning_authority: value.lpa_name
              })}
            />
          </FormControl>}
          <FormControl fullWidth margin='normal'>
            <Autocomplete
              disablePortal
              id="nca"
              options={nationalCharacterAreasAutocomplete}
              // inputValue={props.updatedProjectDetails.p_national_character_area}
              value={props.updatedProjectDetails.p_national_character_area}
              renderInput={(params) => <TextField {...params} label="National Character Area" />}
              // onInputChange={(e, value) => props.setUpdatedProjectDetails({
              //   ...props.updatedProjectDetails,
              //   p_national_character_area: value
              // })}
              onChange={(e, value) => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                p_national_character_area: value.label
              })}
            />
          </FormControl>


          {!props.updatedProjectDetails.unit_project && <Grid item xs={12} md={12} lg={12} sx={{ mb: 2, mt: 2 }}>
            <TextField
              fullWidth
              className="formField"
              label="Biodiversity Gain Sites Register Number"
              id="defraRef"
              placeholder="Biodiversity Gain Sites Register Number"
              // onChange={e => setProjectData('bng_registration_number', e.target.value)}
              value={props.updatedProjectDetails.bng_registration_number}
              onChange={e => props.setUpdatedProjectDetails({
                ...props.updatedProjectDetails,
                bng_registration_number: e.target.value
              })}
            />
          </Grid>}

          {props.updatedProjectDetails.unit_project && <>
            <FormControl fullWidth margin='normal'>
              <TextField
                fullWidth
                className="formField"
                label="Latitude"
                id="additionalInfo"
                multiline
                rows={1}
                value={props.updatedProjectDetails.latitude}
                onChange={e => props.setUpdatedProjectDetails({
                  ...props.updatedProjectDetails,
                  latitude: e.target.value
                })}
              />
            </FormControl>

            <FormControl fullWidth margin='normal'>
              <TextField
                fullWidth
                className="formField"
                label="Longitude"
                id="additionalInfo"
                multiline
                rows={1}
                value={props.updatedProjectDetails.longitude}
                onChange={e => props.setUpdatedProjectDetails({
                  ...props.updatedProjectDetails,
                  longitude: e.target.value
                })}
              />
            </FormControl>
          </>}


          <FormControlLabel
            control={
              <Checkbox
                value={props.updatedProjectDetails.group_at_project_level}
                checked={props.updatedProjectDetails.group_at_project_level}
                onChange={(e, value) => props.setUpdatedProjectDetails({
                  ...props.updatedProjectDetails,
                  group_at_project_level: value
                })}
              />}
            label='Group at project level'
            sx={{ width: '100%' }}
          />

          <FormControlLabel
            control={
              <Checkbox
                value={props.updatedProjectDetails.use_slider_image}
                checked={props.updatedProjectDetails.use_slider_image}
                onChange={(e, value) => props.setUpdatedProjectDetails({
                  ...props.updatedProjectDetails,
                  use_slider_image: value
                })}
              />}
            label='Use image slider'
            sx={{ width: '100%' }}
          />

          {props.updatedProjectDetails?.unit_project && <>
            <UnitProjectDataInputForm
              isEditPage={true}
              unitProject={props.updatedProjectDetails.unit_project}
              setUnitProject={handleUpdateUnitProject}>
            </UnitProjectDataInputForm></>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 4, pt: 0 }}>
        <Stack direction='row' pb={2} pr={2} spacing={2}>
          <Button onClick={props.handleEditClick} className="removeUppercase">Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => props.handleUpdateProjects(
              props.updatedProjectDetails.p_summary,
              props.updatedProjectDetails.p_description,
              props.updatedProjectDetails.p_location,
              props.projectDetails.status_id,
              props.updatedProjectDetails.p_local_planning_authority,
              props.updatedProjectDetails.p_national_character_area,
              props.updatedProjectDetails.group_at_project_level,
              props.updatedProjectDetails.bng_registration_number,
              props.updatedProjectDetails.latitude,
              props.updatedProjectDetails.longitude,
              props.updatedProjectDetails.use_slider_image,
              props.updatedProjectDetails.unit_project
            )}
            className="removeUppercase"
          >
            Update
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};