import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import EnquiryService from '../../services/enquiry-service';
import { UserInformationProviderContext } from '../../context/user-information-provider';
import { duration } from 'moment';


export const DialogInvestmentProjectEnquiry = ({ active, investmentProjectId, onSubmit, onCancel }) => {

  const userInformationContext = useContext(UserInformationProviderContext);
  const enquiryService = new EnquiryService();
  const [details, setDetails] = useState({
    name: '',
    organisation: '',
    email: '',
    amount: '',
    message: ''
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleDetailsChange = (event) => {
    setDetails({ ...details, [event.target.name]: event.target.value });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    setFormSubmitted(true);
    if (details.name && details.organisation && details.email && details.message) {


      enquiryService.sendInvestmentProjectEnquiry(details, investmentProjectId)
        .then(() => {
          userInformationContext.openSnackbar({ open: true, message: 'Enquiry sent successfully.', backgroundColor: 'green', duration: 10000 });

          onSubmit();
        });
    }

  };

  return (
    <Dialog maxWidth='sm' fullWidth open={active}>
      <DialogTitle sx={{ p: 8, pb: 4 }}>
        <Typography variant="h5">
          Please enter the detials required below.
        </Typography>
        <Divider sx={{ mt: 4 }} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: 2 }}>

          <FormControl sx={{ mb: 1, width: '100%' }}>
            <TextField
              className="formField"
              label="Name"
              error={formSubmitted && !details.name}
              name='name'
              value={details.name}
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl>
          <FormControl sx={{ mb: 1, width: '100%' }}>
            <TextField
              className="formField"
              label="Organisation"
              error={formSubmitted && !details.organisation}
              name='organisation'
              value={details.organisation}
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl>
          <FormControl sx={{ mb: 1, width: '100%' }}>
            <TextField
              className="formField"
              label="Email"
              error={formSubmitted && !details.email}
              name='email'
              value={details.email}
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl>
          <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
            <InputLabel htmlFor="total-price">Amount</InputLabel>
            <OutlinedInput
              required={true}
              inputProps={{ min: 0 }}
              startAdornment={<InputAdornment position="start">£</InputAdornment>}

              value={details.amount}
              label="Amount"
              name='amount'
              placeholder="Amount looking to fund."
              type="number"
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl>
          <FormControl sx={{ mb: 1, width: '100%' }}>
            <TextField
              label="Message"
              minRows={5}
              multiline={true}
              error={formSubmitted && !details.message}
              name='message'
              value={details.message}
              onChange={e => handleDetailsChange(e)}
            />
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Stack direction='row' pb={2} pr={2} spacing={2}>
          <Button onClick={() => handleCancel()} className="removeUppercase">Cancel</Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => handleConfirm()}
            className="removeUppercase"
          >
            Send enquiry
          </Button>
        </Stack>
      </DialogActions>
    </Dialog >
  );
}


