import { Button, Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';

const UnitProjectDataInputForm = ({ unitProject, setUnitProject, isEditPage }) => {

  return (
    <>
      {unitProject && <>

        <FormControl sx={{ width: '100%', mb: 2 }}>
          <InputLabel htmlFor="total-price">Price unit*</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name='price_unit'
            value={unitProject.price_unit || ''}
            label="Please select..."
            onChange={(e) => setUnitProject(e, false)}
          >
            <MenuItem key={0} value='tonne'>Tonne</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          control={<Checkbox
            checked={unitProject.hide_price || false}
            onChange={(e) => setUnitProject(e, true)}
            name='hide_price'
          />}
          label="Hide price from buyers"
          sx={{ color: "#00000099" }} />


        <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
          <InputLabel htmlFor="total-price">Validator</InputLabel>
          <OutlinedInput
            fullWidth
            className="formField"
            name='validator'
            label="Validator"
            type="text"
            value={unitProject.validator || ''}
            onChange={(e) => setUnitProject(e, false)}
          />
        </FormControl>


        <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
          <InputLabel htmlFor="total-price">Project length (Years)</InputLabel>
          <OutlinedInput
            fullWidth
            className="formField"
            name='project_length_years'
            label="Project length (Years)"
            type="number"
            step="1"
            value={unitProject.project_length_years || ''}
            onChange={(e) => setUnitProject(e, false)}
          />
        </FormControl>

        <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
          <InputLabel htmlFor="total-price">Designated Conservation Areas</InputLabel>
          <OutlinedInput
            fullWidth
            className="formField"
            name='designated_conservation_areas'
            label="Designated Conservation Areas"
            type="text"
            value={unitProject.designated_conservation_areas || ''}
            onChange={(e) => setUnitProject(e, false)}
          />
        </FormControl>

        <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
          <InputLabel htmlFor="total-price">Ha restored</InputLabel>
          <OutlinedInput
            fullWidth
            className="formField"
            name='hectares_restored'
            label="Ha restored"
            type="number"
            step="1"
            value={unitProject.hectares_restored || ''}
            onChange={(e) => setUnitProject(e, false)}
          />
        </FormControl>

        <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
          <InputLabel htmlFor="total-price">Blocking and dams</InputLabel>
          <OutlinedInput
            fullWidth
            className="formField"
            name='blocking_and_dams'
            label="Blocking and dams"
            type="number"
            step="0.1"
            value={unitProject.blocking_and_dams || ''}
            onChange={(e) => setUnitProject(e, false)}
          />
        </FormControl>

        <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
          <InputLabel htmlFor="total-price">Reprofiling</InputLabel>
          <OutlinedInput
            fullWidth
            className="formField"
            name='reprofiling'
            label="Reprofiling"
            type="number"
            step="0.1"
            value={unitProject.reprofiling || ''}
            onChange={(e) => setUnitProject(e, false)}
          />
        </FormControl>

        <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
          <InputLabel htmlFor="total-price">Number of dams</InputLabel>
          <OutlinedInput
            fullWidth
            className="formField"
            name='number_of_dams'
            label="Number of dams"
            type="number"
            step="1"
            value={unitProject.number_of_dams || ''}
            onChange={(e) => setUnitProject(e, false)}
          />
        </FormControl>

        <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
          <InputLabel htmlFor="total-price">Grid reference</InputLabel>
          <OutlinedInput
            fullWidth
            className="formField"
            name='grid_reference'
            label="Grid reference"
            type="text"
            value={unitProject.grid_reference || ''}
            onChange={(e) => setUnitProject(e, false)}
          />
        </FormControl>

        {!isEditPage && <>

          <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
            <InputLabel htmlFor="total-price">Latitude</InputLabel>
            <OutlinedInput
              fullWidth
              className="formField"
              name='latitude'
              label="Latitude"
              type="text"
              value={unitProject.latitude || ''}
              onChange={(e) => setUnitProject(e, false)}
            />
          </FormControl>

          <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
            <InputLabel htmlFor="total-price">Longitude</InputLabel>
            <OutlinedInput
              fullWidth
              className="formField"
              name='longitude'
              label="Longitude"
              type="text"
              value={unitProject.longitude || ''}
              onChange={(e) => setUnitProject(e, false)}
            />
          </FormControl>

        </>}

      </>}
    </>
  );
};

export default UnitProjectDataInputForm;