import { useEffect, useState } from 'react';
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { Download } from '@mui/icons-material';

export const GraphBarChartStacked = ({
  title,
  series,
  xAxisDataKey,
  additionalSeriesIgnoreKeys,
  transactions,
  valueFormatStringValue,
  customValueString,
  allowDownload
}) => {

  const seriesIgnoreKeys = ['monthYear', 'year', 'month', 'displayIndex', 'region', 'lpa', 'unit_type'];
  // const isMobileMode = useMediaQuery(gaiaTheme.breakpoints.down('sm'));
  const [dataset, setDataset] = useState();
  const [formattedSeries, setFormattedSeries] = useState();
  const colors = [
    '#84a736', // Fresh and lively - 600
    '#b3cb78', // Soft green - 300
    '#dee9c4', // Pale green - 100
    '#93b73d', // Bright green - 500
    '#5c8024', // Subdued green  - 800
    '#c9da9e', // Pastel green - 200
    '#a3c15a', // Bright green - 400
    '#70942c', // Earthy green - 700
    '#f2f6e7', // Very light green - 50
    '#3a5f13', // Darker shade - 900
  ];
  const [componentReady, setComponentReady] = useState(false);

  useEffect(() => {

    if (transactions) {
      generateDataSet();
    }

    if (additionalSeriesIgnoreKeys?.length) {
      additionalSeriesIgnoreKeys.forEach(key => {
        seriesIgnoreKeys.push(key);
      });
      setComponentReady(true);
    } else {
      setComponentReady(true);
    }

  }, [additionalSeriesIgnoreKeys, transactions]);

  const generateDataSet = () => {
    const dataArray = transactions;

    setDataset(dataArray.sort((a, b) => a.displayIndex - b.displayIndex));

    const valueFormatter = (value) => `${value.toFixed(2)} ${valueFormatStringValue ? valueFormatStringValue : ''}`;
    const formatted = Object.keys(dataArray[0]).filter(key => !seriesIgnoreKeys.includes(key)).map(datum => {
      return {
        dataKey: datum,
        label: customValueString ? customValueString : datum,
        stack: 'total',
        valueFormatter
      };
    });

    setFormattedSeries(formatted);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = e => {
    e.preventDefault();
    let headers = [[xAxisDataKey, customValueString]];
    let searchesCsv = transactions.reduce((acc, datum) => {
      // const { unit_type, value } = datum;
      acc.push([datum[xAxisDataKey].split(',').join(' - '), `${datum.value?.toFixed(2)} ${valueFormatStringValue ? valueFormatStringValue : ''}`].join(','));
      return acc;
    }, []);
    downloadFile({
      data: [...headers, ...searchesCsv].join('\n'),
      fileName: `${title?.toLocaleLowerCase().split(' ').join('_')}.csv`,
      fileType: 'text/csv',
    });
  };

  return (
    <>
      {componentReady && dataset && (series || formattedSeries) && <Paper variant='outlined' sx={{ p: 4, height: '100%' }}>
        <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant='subtitle1' sx={{ flex: 1 }}>
            <b>{title}</b>
          </Typography>

          {allowDownload && <IconButton size='medium' onClick={exportToCsv}>
            <Download></Download>
          </IconButton>}
          {/* {tooltip && !isMobileMode && <TooltipButton
            text={tooltip}
            placement='right' />} */}
        </Stack>
        <Box sx={{ width: '100%', height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', mt: 2 }}>
            {series?.map((entry, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', width: '180px' }}>
                <Box sx={{
                  width: 20, height: 20, borderRadius: '5px',
                  backgroundColor: colors[index],
                  mr: 1
                }}></Box>
                <Typography sx={{ mr: 3, fontWeight: 500 }}>{entry.label}</Typography>
              </Box>
            ))}
          </Box>
          <BarChart
            dataset={dataset}
            xAxis={[{ scaleType: 'band', dataKey: xAxisDataKey ? xAxisDataKey : 'month', tickPlacement: 'middle', tickLabelPlacement: 'middle' }]}
            yAxis={[{ disableLine: true }]}
            series={series ? series : formattedSeries}
            grid={{ horizontal: true }}
            slotProps={{
              legend: { hidden: true }
            }}
            height={400}
            borderRadius={5}
            colors={colors}
            axisHighlight={{ x: 'none' }}
          />
        </Box>
      </Paper>}
    </>
  );
};