import { useContext, useEffect, useState } from 'react';
import { HubDetailsContext } from '../../context/hub-details';
import { Container, IconButton, Typography } from '@mui/material';
import AnalyticsService from '../../services/analytics-service';
import { GraphBarChartStacked } from '../../components/graphs/bar-chart-stacked';
import { GraphDonutChart } from '../../components/graphs/donut-chart';
import { Download } from '@mui/icons-material';

export const AnalyticsDashboardTile = ({
  title,
  groupBy,
  searchYear,
  searchMonth,
  isSearchAnalytics,
  customValueString,
  valueFormatStringValue,
  useDonutChart,
  available,
  allowDownload
}) => {

  const hubContext = useContext(HubDetailsContext);
  const analyticsService = new AnalyticsService();

  const [analytics, setAnalytics] = useState();

  useEffect(() => {
    if (!isSearchAnalytics) {
      analyticsService.getAnalytics(groupBy ? `?groupBy=${groupBy}${available ? '&available=true' : ''}` : '')
        .then(response => {
          setAnalytics(response);
        });
    } else {
      analyticsService.getSearchAnalytics(groupBy ? `?groupBy=${groupBy}${searchMonth ? '&' : ''}${searchMonth ? 'month=' : ''}${searchMonth ? searchMonth : ''}${searchYear ? '&' : ''}${searchYear ? 'year=' : ''}${searchYear ? searchYear : ''}` : '')
        .then(response => {
          setAnalytics(response);
        });
    }
  }, [hubContext, groupBy, searchMonth, searchYear]);

  return (
    <>
      <Container maxWidth="lg" sx={{ pt: 2, pb: 2 }}>

        {analytics?.length > 0 && !useDonutChart && <GraphBarChartStacked
          title={title}
          transactions={analytics}
          xAxisDataKey={groupBy}
          customValueString={customValueString}
          valueFormatStringValue={valueFormatStringValue}
          allowDownload={allowDownload}
        >
        </GraphBarChartStacked>}

        {analytics?.length > 0 && useDonutChart && <GraphDonutChart
          title={title}
          dataset={analytics}
          allowDownload={allowDownload}
          xAxisDataKey={groupBy}
          customValueString={customValueString}
          valueFormatStringValue={valueFormatStringValue}
        >
        </GraphDonutChart>}

        {analytics?.length === 0 && <Typography
          variant="h6"
          sx={{ mb: 2, mt: 2 }}
        >
          No results
        </Typography>}

      </Container>
    </>
  );
};