import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Alert, Checkbox, FormControlLabel, MenuItem, Select } from '@mui/material';


const DialogCreateIssuance = (
  {active, onCancel, issuance, onSubmit}
) => {

  const [localIssuance, setLocalIssuance] = useState({});
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false)
    if (issuance) {
      setLocalIssuance(issuance)
    } else {
      setLocalIssuance({})
    }
  }, [active])

  const handleSubmit = () => {
    setHasError(false)
    if (localIssuance.vintage && localIssuance.units && localIssuance.unit_type) {
      onSubmit(localIssuance)
    } else {
      setHasError(true)
    }
  }

  const handleChange = (e) => {
    setLocalIssuance({...localIssuance, [e.target.name]: e.target.value})
  }

  const handleSelect = (value) => {
    setLocalIssuance({ ...localIssuance, unit_type: value})
  } 

  const handleCheckbox = (value) => {
    setLocalIssuance({ ...localIssuance, hide_price: value });
  } 

  return (
    <Dialog open={active} >
      <DialogTitle sx={{ pb: '5px' }}>{issuance ? 'Edit issuance' : 'Add issuance'}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Enter details regarding this issuance.
        </DialogContentText>

        {hasError && <Alert severity='error'>Please enter all the required information below.</Alert>}
        <Box
          component="form"
          noValidate
          autoComplete="off">
          {localIssuance && <>
            <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
              <InputLabel htmlFor="total-price">Vintage*</InputLabel>
              <OutlinedInput
                fullWidth
                className="formField"
                name='vintage'
                label="Vintage*"
                type="text"
                value={localIssuance.vintage || ''}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
              <InputLabel htmlFor="total-price">Units*</InputLabel>
              <OutlinedInput
                fullWidth
                className="formField"
                name='units'
                label="Units*"
                type="number"
                step='1'
                value={localIssuance.units || ''}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl fullWidth margin='normal' sx={{  mb: 1 }}>
              <InputLabel id="demo-simple-select-label">Unit type</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={localIssuance.unit_type || ''}
                label="Unit type"
                onChange={(event) => handleSelect(event.target.value)}
              >
                <MenuItem key={0} value='Pending Issuance Units (PIUs)'>Pending Issuance Units (PIUs)</MenuItem>
                <MenuItem key={1} value='Verified Carbon Units (VCUs)'>Verified Carbon Units (VCUs)</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
              <InputLabel htmlFor="total-price">Verifier</InputLabel>
              <OutlinedInput
                fullWidth
                className="formField"
                name='verifier'
                label="Verifier"
                type="text"
                value={localIssuance.verifier || ''}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl fullWidth margin='normal' sx={{ mb: 1 }}>
              <InputLabel htmlFor="total-price">Price</InputLabel>
              <OutlinedInput
                fullWidth
                className="formField"
                name='price'
                label="Price"
                type="number"
                step="0.1"
                value={localIssuance.price || ''}
                onChange={handleChange}
              />
            </FormControl>

            <FormControlLabel 
            control={
            <Checkbox checked={!!localIssuance.hide_price} 
            onChange={e => handleCheckbox(e.target.checked)} />} 
            label="Hide price from buyers" 
            sx={{ color: "#00000099" }} />

          </>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={() => onCancel()} className="removeUppercase">Cancel</Button>
        <Button
             variant="contained"
             color="primary"
             disableElevation
             onClick={handleSubmit}
             className="removeUppercase">
             Save
          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCreateIssuance;