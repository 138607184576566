import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, FormControl, InputLabel, Select, TextField, MenuItem, FormControlLabel, Checkbox, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArticleService from '../../../shared/services/article-service';
import { ImageFileUpload } from '../../../shared/components/image-file-upload';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DeleteOutline, SearchOutlined } from '@mui/icons-material';
import { GaiaTextField } from '../../../shared/components/form-elements/text-field';
import AdminArticleGroupService from '../../services/admin-article-service';

const CreateArticleForm = ({ articleId }) => {


  const articleService = new ArticleService();
  const adminArticleGroupService = new AdminArticleGroupService();
  const navigate = useNavigate();

  const [articleGroups, setArticleGroups] = useState([]);
  const [createdArticle, setCreatedArticle] = useState();
  const [fileData, setFileData] = useState([]);
  const [downloadableResource, setDownloadableResource] = useState([]);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{
        'color': ['color-picker']
      }, { 'background': [] }],
      ['link', 'image', 'video'],
      [{ 'align': [] }],
    ],

  };

  // const formats = [
  //   'header', 'font',
  //   'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background', 'align',
  //   'super', 'subscript',
  //   'list', 'bullet', 'indent',
  //   'link', 'image', 'video'
  // ];

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "background",
    "color",
    "code",
    "align",
    "script",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "size"
  ];

  useEffect(() => {
    adminArticleGroupService.getAllArticleGroups()
      .then(response => {
        setArticleGroups(response);

      });
  }, []);

  useEffect(() => {
    if (articleId) {
      console.log('here');
      articleService.getOneArticle(articleId)
        .then(response => {
          setCreatedArticle(response);
          console.log(response);
        });
    } else {
      createArticle();
    }
  }, [articleId]);

  // Create the initial article object and add arrays for the specific areas.
  function createArticle(title_copy = '', subtitle_copy = '', body_copy = '', primary_image_url = 'https://images.unsplash.com/photo-1567891643126-c9240c809747', article_group_id = null) {
    const article = {
      title_copy: title_copy,
      subtitle_copy: subtitle_copy,
      body_copy: body_copy,
      primary_image_url: primary_image_url,
      article_group_id: article_group_id,
      external_url: '',
      og_meta_image_url: '',
      format: 'article',
      resource_is_external: false,
      download_resource: false
    };

    setCreatedArticle(article);
  }

  // Set the required article data.
  function setArticleData(formControl, value) {
    const article = createdArticle;
    article[formControl] = value;

    if (formControl === 'resource_is_external' && !value) {
      article.external_url = '';
      article.download_resource = false;
    } else if (formControl === 'resource_is_external' && value) {
      article.body_copy = '';
    }

    setCreatedArticle({ ...article });
  }

  const getMetaInformation = () => {
    if (createdArticle.og_meta_image_url) {
      setCreatedArticle({ ...createdArticle, og_meta_image_url: '' });
    } else {
      articleService.getOgMetaTag(createdArticle.external_url)
        .then(async response => {
          console.log(response);

          let resourceAddress = '';

          if (response.openGraph?.image?.url) {
            resourceAddress = response.openGraph.image.url;
          } else if (response.hybridGraph?.image) {
            resourceAddress = response.hybridGraph.image;
          }

          if (resourceAddress) {
            setArticleData('og_meta_image_url', resourceAddress);
            setFileData([]);
          }
        });
    }
  };

  // Submit the article
  function submitArticle(event) {
    event.preventDefault();

    const form = new FormData();
    form.append("files", fileData[0]);
    form.append("downloadable_resource", downloadableResource[0]);

    form.append('article', JSON.stringify({ ...createdArticle, id: articleId }));

    if (articleId) {
      articleService.editArticle(articleId, form)
        .then(() => {
          navigate("/admin/articles");
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      articleService.createNewArticle(form)
        .then(() => {
          navigate("/admin/articles");
        })
        .catch(err => {
          console.log(err);
        });
    }
  }


  return (<>
    {((articleId || createdArticle) && articleGroups) && <Box component="form" onSubmit={submitArticle}>
      <FormControl fullWidth margin="normal" required>
        <InputLabel id="articleGroupLabel">Article Group</InputLabel>
        <Select
          fullWidth
          className="formField"
          label="Article Group"
          labelId="articleGroupLabel"
          id="articleGroup"
          placeholder="Select article group"
          value={createdArticle.article_group_id || ''}
          onChange={e => setArticleData('article_group_id', e.target.value)}
        >
          {articleGroups.map(group => {
            return <MenuItem key={group.id} value={group.id}>{group.title_copy}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal" required>
        <InputLabel id="articleGroupLabel">Format</InputLabel>
        <Select
          fullWidth
          className="formField"
          label="Format"
          value={createdArticle?.format || ''}
          placeholder="Select a format"
          onChange={e => setArticleData('format', e.target.value)}
        >

          <MenuItem key={1} value={'article'}>Article</MenuItem>
          <MenuItem key={2} value={'pdf'}>PDF</MenuItem>
          <MenuItem key={2} value={'external_website'}>External website</MenuItem>

        </Select>
      </FormControl>

      <GaiaTextField
        label="Title"
        value={createdArticle.title_copy}
        charchterCount={255}
        minRows={1}
        placeholder="Title"
        formControlName='title_copy'
        onChange={setArticleData}>
      </GaiaTextField>
      <GaiaTextField
        label="Short description"
        value={createdArticle.subtitle_copy}
        charchterCount={255}
        minRows={1}
        placeholder="Short description"
        formControlName='subtitle_copy'
        onChange={setArticleData}>
      </GaiaTextField>

      {(createdArticle?.format === 'pdf' || createdArticle?.format === 'external_website') && <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          className="formField"
          label="External URL"
          value={createdArticle.external_url}
          placeholder="Provide the URL for this resource"
          onChange={e => setArticleData('external_url', e.target.value)}
          margin="normal"
        />

        {createdArticle?.format === 'external_website' && <Button
          variant="contained"
          color="primary"
          onClick={getMetaInformation}
          disableElevation
          disabled={!createdArticle.external_url}
          startIcon={createdArticle.og_meta_image_url ? <DeleteOutline /> : <SearchOutlined />}
          className='removeUppercase'
        >
          {createdArticle.og_meta_image_url ? 'Remove image' : 'Search for Meta image'}
        </Button>}
      </Box>}

      {createdArticle?.format === 'article' && <Box sx={{ mt: 3, mb: 10 }}>
        {createdArticle && <ReactQuill
          theme="snow"
          formats={formats}
          modules={modules}
          style={{ height: '400px', marginBottom: '24px' }}
          placeholder='Please enter the article content'
          value={createdArticle.body_copy || ''}
          onChange={e => setArticleData('body_copy', e)}
        />}

      </Box>}

      {createdArticle?.og_meta_image_url && <Box sx={{ maxWidth: '400px' }}>
        <img src={createdArticle.og_meta_image_url} alt='found meta tag'></img>
      </Box>}

      <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mb: 1, mt: 2 }}>
        Article cover image::
      </Typography>
      {!createdArticle?.og_meta_image_url && <ImageFileUpload fileData={fileData} setFileData={setFileData} imageCountLimit={1}></ImageFileUpload>}

      {!createdArticle?.og_meta_image_url && createdArticle.format === 'article' && <>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mb: 1, mt: 2 }}>
          If you would like to add a downloadable resource to the bottom of this article please add it below. A 'Download' button will be added below any article content.
        </Typography>
        <ImageFileUpload fileData={downloadableResource} downloadableResource={true} setFileData={setDownloadableResource} imageCountLimit={1}></ImageFileUpload>
      </>
      }

      <Button
        variant="contained"
        color="primary"
        disableElevation
        type="submit"
        //disabled={imageUploadInProgress}
        startIcon={<CheckCircleOutlineIcon />}
        className='removeUppercase'
        sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
      >
        {articleId ? 'Save changes' : 'Submit Article'}
      </Button>
    </Box >}
  </>
  );
};

export default CreateArticleForm;;