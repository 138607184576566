import { GrassOutlined, LinkOffOutlined, SpaOutlined, Verified, Water } from '@mui/icons-material';
import Lock from '@mui/icons-material/Lock';
import { Alert, Box, Button, Chip, Divider, Grid, Paper, Skeleton, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { BngProjectDisplayNotes } from './bng-project-display-notes';
import gaiaTheme from '../../theme';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { UnGoalsDisplay } from './investment-project/un-goals';
import { CustomProjectIconDisplay } from './project-resources/custom-project-icon-display';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProjectDetailSummary = ({ enquirySent, freeTier, projectDetails, groupAtProjectlevel, isMainHub, hubId, isAdmin, isAdminPage }) => {
  const isTablet = useMediaQuery(gaiaTheme.breakpoints.down('md'));
  const isMobile = useMediaQuery(gaiaTheme.breakpoints.down('sm'));

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {projectDetails && <Box sx={{ mt: 4 }}>
        <Grid container spacing={2} sx={{ pl: '6px' }} flexDirection={isTablet ? 'column-reverse' : 'row'}>

          {enquirySent === true && <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Alert severity="success">Enquiry Sent</Alert>
          </Grid>}

          <Grid item xs={12} md={9} lg={9}>
            <Box>
              <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                {!freeTier ? (isMainHub ? projectDetails.p_local_planning_authority : projectDetails.p_summary) : (
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <Skeleton animation={false} width='50%' height='40px' />
                    <Lock sx={{ color: '#00000099' }} />
                  </Stack>
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body" color="text.primary" sx={{ mb: '20px', whiteSpace: 'pre-wrap' }}>
                {isMainHub ? projectDetails.p_summary : projectDetails.unit_project ? projectDetails.p_local_planning_authority : projectDetails?.custom_region_tag}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            {projectDetails.bng_gov_registered && <Stack direction={'row'} justifyContent={'flex-end'}>
              <Typography variant="body" color={'#1DA1F2'} sx={{ mr: 1 }}>
                BNG registered
                <br></br>
                {projectDetails?.bng_registration_number && <>Registration number: {projectDetails.bng_registration_number}</>}
              </Typography>
              <Tooltip title="BNG registered" placement="right" arrow>
                <Verified
                  sx={{
                    color: '#1DA1F2',
                  }} />
              </Tooltip>
            </Stack>}

            <Typography variant="body" color="text.secondary" sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}>
              Added on {projectDetails.dateAdded}
            </Typography>

            {isAdmin && !isAdminPage && <Button
              variant="outlined"
              sx={{ justifyContent: "flex-end", ml: "auto", float: "right", mt: 1 }}
              color="info"
              className='removeUppercase'
              component={Link}
              to={`/admin/bng-project/${projectDetails.p_id}`}
              target='_self'
            >
              Go to admin page
            </Button>}
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pl: '6px' }}>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Box>
              <Divider sx={{ mt: '34px', mb: '50px' }} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    Overview
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography variant="subtitle1" color="text.primary">
                      <b style={{ marginRight: '10px' }}>Description</b>
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography variant="subtitle1" color="text.primary" sx={{ whiteSpace: 'pre-wrap' }}>
                      {!freeTier ? (projectDetails.p_description) : (
                        <Lock sx={{ color: '#00000099' }} />
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                {!projectDetails.unit_project && <>
                  <Grid item xs={12} md={4} lg={3}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        <b style={{ marginRight: '10px' }}>Local Planning Authority</b>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={8} lg={9}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        {!freeTier ? (projectDetails.p_local_planning_authority) : (
                          <Lock sx={{ color: '#00000099' }} />
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                </>}

                {!projectDetails.unit_project && <>
                  <Grid item xs={12} md={4} lg={3}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        <b style={{ marginRight: '10px' }}>National Character Area</b>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={8} lg={9}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        {!freeTier ? (projectDetails.p_national_character_area) : (
                          <Lock sx={{ color: '#00000099' }} />
                        )}
                      </Typography>
                    </Stack>
                  </Grid>
                </>}

                {projectDetails.totalArea > 0 && (
                  <>
                    <Grid item xs={12} md={4} lg={3} >
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          <b style={{ marginRight: '10px' }}>Size</b>
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9} >
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          {!freeTier ? (`${projectDetails.totalArea} hectares`) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </Typography>
                      </Stack>
                    </Grid>
                  </>
                )}
                {!projectDetails.unit_project && <>
                  <Grid item xs={12} md={4} lg={3} >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        <b style={{ marginRight: '10px' }}>Total Units</b>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={8} lg={9} >
                    <Stack spacing={2} direction="row" alignItems="center">
                      {projectDetails.totalBiodiversityUnitsRemaining > 0 && <Typography variant="subtitle1" color="text.primary">
                        <NumericFormat value={projectDetails.totalBiodiversityUnitsRemaining} displayType={'text'} thousandSeparator={true} decimalScale={2} /> units
                      </Typography>}
                      {projectDetails.totalBiodiversityUnitsRemaining === 0 && <Typography variant="subtitle1" color="text.primary">
                        TBC
                      </Typography>}
                    </Stack>
                  </Grid>
                  {groupAtProjectlevel && <>
                    <Grid item xs={12} md={4} lg={3} >
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          <b style={{ marginRight: '10px' }}>Total Hectares</b>
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          <NumericFormat value={Math.round(projectDetails.totalHectaresAvailable)} displayType={'text'} thousandSeparator={true} />
                        </Typography>
                      </Stack>
                    </Grid>
                  </>}
                  <Grid item xs={12} md={4} lg={3}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        <b style={{ marginRight: '10px' }}>Unit Price</b>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={8} lg={9}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      {!freeTier ? (<Typography variant="subtitle1" color="text.primary">
                        {(!projectDetails.hidePrice && projectDetails.totalPrice !== "0") ? (
                          <NumericFormat value={Math.round(projectDetails.totalPrice / projectDetails.totalBiodiversityUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                        ) : ("POA")}
                      </Typography>) : (
                        <Lock sx={{ color: '#00000099' }} />
                      )}

                    </Stack>
                  </Grid>
                  {hubId === 4 && <>
                    <Grid item xs={12} md={4} lg={3}>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          <b style={{ marginRight: '10px' }}>Fractional Unit Availability</b>
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          {projectDetails?.fractional_units_available === true ? 'Available' : 'Unavailable'}
                        </Typography>
                      </Stack>
                    </Grid>
                  </>}
                  {hubId === 4 && <>
                    <Grid item xs={12} md={4} lg={3}>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          <b style={{ marginRight: '10px' }}>Minimum Sale Size</b>
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          {projectDetails?.minimum_sale_size && projectDetails?.minimum_sale_size + ' units'}
                        </Typography>
                      </Stack>
                    </Grid>
                  </>}
                </>}
              </Grid>

              {projectDetails?.un_goals && !isAdminPage && <>
                <Divider sx={{ mt: 6, mb: 6 }} />
                <UnGoalsDisplay isAdmin={false} project={projectDetails} />
              </>}

              {projectDetails.custom_project_icons?.length > 0 && !isAdminPage && <>
                <Divider sx={{ mt: 6, mb: 6 }} />
                <CustomProjectIconDisplay isAdmin={false} project={projectDetails} />
              </>
              }

              {projectDetails.unit_project && <>

                <Divider sx={{ mt: 6, mb: 5 }} />
                <Box sx={{ minWidth: '100%' }}>

                  <Tabs value={activeTab} onChange={handleChangeTab} aria-label="search tabs">
                    <Tab
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      label="Key Stats"
                      className='removeUppercase'
                      {...a11yProps(0)}
                    />
                    <Tab
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      label="Emission Reductions"
                      className='removeUppercase'
                      {...a11yProps(1)}
                    />
                  </Tabs>

                  <TabPanel value={activeTab} index={0} sx={{ mt: 2 }}>
                    <Grid container spacing={2} >
                      {/* <Grid item xs={12} md={4} lg={3} >
                        <Typography variant="subtitle1" color="text.primary">
                          <b style={{ marginRight: '10px' }}>Price</b>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={8} lg={9}>
                       
                        <Typography variant="subtitle1" color="text.primary">
                          {projectDetails.unit_project.price && projectDetails.unit_project.price !== 0 && <NumericFormat value={Math.round(projectDetails.unit_project.price)} displayType={'text'} thousandSeparator={true} prefix={'£'} />} per {projectDetails.unit_project.price_unit}
                        </Typography>
                       
                      </Grid> */}

                      {(isAdminPage || !!projectDetails.unit_project.validator) && <>
                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Validation and Verification Body</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              {projectDetails.unit_project.validator}
                            </Typography>
                          </Stack>
                        </Grid>
                      </>}

                      {(isAdminPage || !!projectDetails.unit_project.designated_conservation_areas) && <>
                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Designated Conservation Areas</b>
                            </Typography>
                          </Stack>
                        </Grid>


                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              {projectDetails.unit_project.designated_conservation_areas}
                            </Typography>
                          </Stack>
                        </Grid>
                      </>}

                      <Grid item xs={12} md={4} lg={3} >
                        <Stack spacing={2} direction="row" alignItems="center">
                          <Typography variant="subtitle1" color="text.primary">
                            <b style={{ marginRight: '10px' }}>Duration</b>
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={8} lg={9}>
                        <Stack spacing={2} direction="row" alignItems="center">
                          {!!projectDetails.unit_project.project_length_years && projectDetails.unit_project.project_length_years !== 0 && <Typography variant="subtitle1" color="text.primary">
                            <NumericFormat value={Math.round(projectDetails.unit_project.project_length_years)} displayType={'text'} thousandSeparator={true} /> years
                          </Typography>}
                          {(!projectDetails.unit_project.project_length_years || projectDetails.unit_project.project_length_years === 0) && <Typography variant="subtitle1" color="text.primary">
                            Under development
                          </Typography>}
                        </Stack>
                      </Grid>

                      {(isAdminPage || !!projectDetails.unit_project.hectares_restored) && <>
                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Restoration area</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <NumericFormat value={Math.round(projectDetails.unit_project.hectares_restored)} displayType={'text'} thousandSeparator={true} /> hectares
                            </Typography>
                          </Stack>
                        </Grid>
                      </>}

                      {(isAdminPage || !!projectDetails.unit_project.blocking_and_dams) && <>
                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Blocking and dams</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <NumericFormat value={Math.round(projectDetails.unit_project.blocking_and_dams)} displayType={'text'} thousandSeparator={true} /> metres
                            </Typography>
                          </Stack>
                        </Grid>
                      </>}
                      {(isAdminPage || !!projectDetails.unit_project.reprofiling) && <>
                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Reprofiling</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <NumericFormat value={Math.round(projectDetails.unit_project.reprofiling)} displayType={'text'} thousandSeparator={true} /> metres
                            </Typography>
                          </Stack>
                        </Grid>
                      </>}

                      {(isAdminPage || !!projectDetails.unit_project.number_of_dams) && <>
                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Number of dams</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <NumericFormat value={Math.round(projectDetails.unit_project.number_of_dams)} displayType={'text'} thousandSeparator={true} />
                            </Typography>
                          </Stack>
                        </Grid>
                      </>}

                      {isAdminPage && <>
                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Grid reference</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              {projectDetails.unit_project.grid_reference}
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Latitude</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              {projectDetails.latitude}
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={4} lg={3} >
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              <b style={{ marginRight: '10px' }}>Longitude</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} md={8} lg={9}>
                          <Stack spacing={2} direction="row" alignItems="center">
                            <Typography variant="subtitle1" color="text.primary">
                              {projectDetails.longitude}
                            </Typography>
                          </Stack>
                        </Grid>


                      </>}

                    </Grid>

                  </TabPanel>
                  <TabPanel value={activeTab} index={1}>

                    <Stack direction={'row'} alignItems={'center'} sx={{ mt: 1, mb: 4 }}>
                      <Typography sx={{ mr: 1 }} variant="subtitle1">
                        Total Forecast Emission Reduction:
                      </Typography>
                      {!!projectDetails.unit_project?.total_forecast_emission_reduction && projectDetails.unit_project?.total_forecast_emission_reduction !== 0 && <Typography variant="h6">
                        <NumericFormat value={projectDetails.unit_project?.total_forecast_emission_reduction} displayType={'text'} thousandSeparator={true} /> tCO<sub>2</sub>e
                      </Typography>}

                      {(!projectDetails.unit_project?.total_forecast_emission_reduction || projectDetails.unit_project?.total_forecast_emission_reduction === 0) && <Typography variant="h6">
                        Under development
                      </Typography>}
                    </Stack>


                    <Grid container spacing={2} >

                      <Table>
                        <TableHead>
                          <TableRow sx={{ fontWeight: 600 }}>
                            <TableCell sx={{ fontWeight: 600 }}>Verification year</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Number of units (tCO<sub>2</sub>e)</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Unit type</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Validation and Verification Body</TableCell>
                            {/* <TableCell sx={{ fontWeight: 600 }}>Price</TableCell> */}

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {projectDetails.unit_project.issuances?.map((issuance, index) => {
                            return <>
                              <TableRow
                                key={index}>
                                <TableCell>{issuance.vintage}</TableCell>
                                <TableCell><NumericFormat value={issuance.units} displayType={'text'} thousandSeparator={true} decimalScale={0} /></TableCell>
                                <TableCell>{issuance.unit_type}</TableCell>
                                <TableCell>{issuance.verifier}</TableCell>
                                {/* {!issuance.hide_price && <TableCell><NumericFormat value={issuance.price} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'£'} /></TableCell>}
                                {issuance.hide_price && <TableCell>POA</TableCell>} */}
                              </TableRow>
                            </>;
                          })}

                          {!projectDetails.unit_project.issuances?.length &&
                            <TableRow>
                              <TableCell colSpan={10}>No issuances added</TableCell>
                            </TableRow>}
                        </TableBody>
                      </Table>
                    </Grid>
                  </TabPanel>
                </Box>
              </>}

            </Box>
          </Grid>
        </Grid>

        {projectDetails?.admin_display_notes && <Divider sx={{ mt: 6, mb: 6 }} />}

        <BngProjectDisplayNotes isAdmin={false} project={projectDetails} />

        {(projectDetails.totalAreaHabitats) > 0 && (
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              mt: '40px'
            }}
            variant='outlined'
          >
            <Grid container spacing={2}>
              <Grid item xs={9} md={9} lg={9}>
                <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: '32px' }}>
                  <SpaOutlined color="primary" />
                  <Typography vvariant="subtitle1" color="text.primary">
                    <b>Area Units</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography vvariant="subtitle1" color="text.primary" sx={{ textAlign: "right" }}>
                  <b>
                    {projectDetails.project_area_habitats.every(area => !area.pah_hide_price) ? (
                      <NumericFormat value={Math.round(projectDetails.totalAreaPrice / projectDetails.totalAreaUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    ) : ("POA")}
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mb: '32px' }} />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <TableContainer>
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Broad Habitat</TableCell>
                      <TableCell>Habitat</TableCell>
                      {!groupAtProjectlevel && <TableCell align="right">Area (hectares)</TableCell>}
                      <TableCell align="right">Available Units</TableCell>
                      <TableCell align="right">Unit Price (£)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectDetails.project_area_habitats.map((areaHabitat, index) => (
                      <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">{areaHabitat.pah_broad_habitat}</TableCell>
                        <TableCell>
                          {!freeTier ? (areaHabitat?.pah_habitat) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        {!groupAtProjectlevel && <TableCell align="right">
                          {!freeTier ? (areaHabitat.pah_area) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>}
                        <TableCell align="right">
                          {areaHabitat.biodiversity_units_remaining === 0 ? 'TBC' : !freeTier ? (areaHabitat.biodiversity_units_remaining) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (
                            !areaHabitat.pah_hide_price ? (
                              <NumericFormat value={Math.round(areaHabitat.pah_total_price / areaHabitat.pah_biodiversity_units)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            ) : ("POA")
                          ) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === true)?.length > 0 && (
                <>
                  <Typography variant="subtitle1" color="primary" sx={{ pb: 1, mt: 4 }}>
                    <b>Units closed / deleted</b>
                  </Typography>
                  <TableContainer>
                    <Table aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ pl: 0 }}>Status</TableCell>
                          <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                          <TableCell>Habitat</TableCell>
                          <TableCell align="right">Area (hectares)</TableCell>
                          <TableCell align="right">Units Remaining</TableCell>
                          <TableCell align="right">Units Sold</TableCell>
                          <TableCell align="right">Unit Price (£)</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === true).map((filteredAreaHabitat, index) => (
                          <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              <Chip
                                edge="end"
                                label={filteredAreaHabitat.deleted_at ? 'Deleted' : 'Closed'}
                                variant='outlined'
                                color={filteredAreaHabitat.deleted_at ? 'error' : 'info'}
                                sx={{ mr: 1 }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              {filteredAreaHabitat.pah_broad_habitat}
                            </TableCell>
                            <TableCell>{filteredAreaHabitat.pah_habitat}</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.pah_area}</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.biodiversity_units_remaining
                            }</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.units_sold}</TableCell>
                            <TableCell align="right">
                              <NumericFormat value={filteredAreaHabitat.pah_total_price / filteredAreaHabitat.pah_biodiversity_units} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            </TableCell>
                            <TableCell align="right">
                              <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                              </Stack>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Grid>
          </Paper>
        )}
        {(projectDetails.totalHedgerowHabitats > 0) && (
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              mt: '15px'
            }}
            variant='outlined'
          >
            <Grid container spacing={2}>
              <Grid item xs={9} md={9} lg={9}>
                <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: '32px' }}>
                  <GrassOutlined color="primary" />
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Hedgerow Units</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "right" }}>
                  <b>
                    {projectDetails.project_hedgerow_habitats.every(area => !area.phh_hide_price) ? (
                      <NumericFormat value={Math.round(projectDetails.totalHedgerowPrice / projectDetails.totalHedgerowUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    ) : ("POA")}
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mb: '32px' }} />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <TableContainer>
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Broad Habitat</TableCell>
                      <TableCell>Habitat</TableCell>
                      <TableCell align="right">Length (km)</TableCell>
                      <TableCell align="right">Available Units</TableCell>
                      <TableCell align="right">Unit Price (£)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectDetails.project_hedgerow_habitats.map((hedgerowHabitat, indexx) => (
                      <TableRow key={indexx} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {hedgerowHabitat.phh_broad_habitat}
                        </TableCell>
                        <TableCell>
                          {!freeTier ? (hedgerowHabitat?.phh_habitat) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (hedgerowHabitat.phh_area) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (hedgerowHabitat.biodiversity_units_remaining) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (
                            !hedgerowHabitat.phh_hide_price ? (
                              <NumericFormat value={Math.round(hedgerowHabitat.phh_total_price / hedgerowHabitat.phh_biodiversity_units)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            ) : ("POA")
                          ) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {projectDetails.project_hedgerow_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === true)?.length > 0 && (
                <>
                  <Typography variant="subtitle1" color="primary" sx={{ pb: 1, mt: 4 }}>
                    <b>Units closed / deleted</b>
                  </Typography>
                  <TableContainer>
                    <Table aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ pl: 0 }}>Status</TableCell>
                          <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                          <TableCell>Habitat</TableCell>
                          <TableCell align="right">Area (hectares)</TableCell>
                          <TableCell align="right">Units Remaining</TableCell>
                          <TableCell align="right">Units Sold</TableCell>
                          <TableCell align="right">Unit Price (£)</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.phh_closed_status === true).map((filteredAreaHabitat, index) => (
                          <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              <Chip
                                edge="end"
                                label={filteredAreaHabitat.deleted_at ? 'Deleted' : 'Closed'}
                                variant='outlined'
                                color={filteredAreaHabitat.deleted_at ? 'error' : 'info'}
                                sx={{ mr: 1 }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              {filteredAreaHabitat.phh_broad_habitat}
                            </TableCell>
                            <TableCell>{filteredAreaHabitat.phh_habitat}</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.phh_area}</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.biodiversity_units_remaining
                            }</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.units_sold}</TableCell>
                            <TableCell align="right">
                              <NumericFormat value={filteredAreaHabitat.phh_total_price / filteredAreaHabitat.phh_biodiversity_units} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            </TableCell>
                            <TableCell align="right">
                              <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                              </Stack>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Grid>
          </Paper>
        )}
        {(projectDetails.totalWatercourseHabitats > 0) && (
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              mt: '15px'
            }}
            variant='outlined'
          >
            <Grid container spacing={2}>
              <Grid item xs={9} md={9} lg={9}>
                <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: '32px' }}>
                  <Water color="primary" />
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Watercourse Units</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "right" }}>
                  <b>
                    {projectDetails.project_watercourse_habitats.every(area => !area.pwh_hide_price) ? (
                      <NumericFormat value={Math.round(projectDetails.totalWatercoursePrice / projectDetails.totalWatercourseUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    ) : ("POA")}
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mb: '32px' }} />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <TableContainer>
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Broad Habitat</TableCell>
                      <TableCell>Habitat</TableCell>
                      <TableCell align="right">Length (km)</TableCell>
                      <TableCell align="right">Available Units</TableCell>
                      <TableCell align="right">Unit Price (£)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectDetails.project_watercourse_habitats.map((watercourseHabitat, index) => (
                      <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {watercourseHabitat.pwh_broad_habitat}
                        </TableCell>
                        <TableCell>
                          {!freeTier ? (watercourseHabitat?.pwh_habitat) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (watercourseHabitat.pwh_area) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (watercourseHabitat.biodiversity_units_remaining) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (
                            !watercourseHabitat.pwh_hide_price ? (
                              <NumericFormat value={Math.round(watercourseHabitat.pwh_total_price / watercourseHabitat.pwh_biodiversity_units)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            ) : ("POA")
                          ) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === true).length > 0 && (
                <>
                  <Typography variant="subtitle1" color="primary" sx={{ pb: 1, mt: 4 }}>
                    <b>Units closed / deleted</b>
                  </Typography>
                  <TableContainer>
                    <Table aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ pl: 0 }}>Status</TableCell>
                          <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                          <TableCell>Habitat</TableCell>
                          <TableCell align="right">Length (km)</TableCell>
                          <TableCell align="right">Units Remaining</TableCell>
                          <TableCell align="right">Units Sold</TableCell>
                          <TableCell align="right">Price (£)</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === true).map((filteredWatercourseHabitat, index) => (
                          <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              <Chip
                                edge="end"
                                label={filteredWatercourseHabitat.deleted_at ? 'Deleted' : 'Closed'}
                                variant='outlined'
                                color={filteredWatercourseHabitat.deleted_at ? 'error' : 'info'}
                                sx={{ mr: 1 }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              {filteredWatercourseHabitat.pwh_broad_habitat}
                            </TableCell>
                            <TableCell>{filteredWatercourseHabitat.pwh_habitat}</TableCell>
                            <TableCell align="right">{filteredWatercourseHabitat.pwh_area}</TableCell>
                            <TableCell align="right">{filteredWatercourseHabitat.biodiversity_units_remaining
                            }</TableCell>
                            <TableCell align="right">{filteredWatercourseHabitat.units_sold}</TableCell>
                            <TableCell align="right">
                              <NumericFormat value={filteredWatercourseHabitat.pwh_total_price} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            </TableCell>
                            <TableCell align="right">
                              <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Grid>
          </Paper>
        )}
      </Box>}
    </>
  );
};

export default ProjectDetailSummary;