import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import * as data from '../data/cornwall.json';  // GeoJSON data
import { styled } from "@mui/material/styles";
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InvestmentProjectService from '../services/investment-project-service';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "transparent",
    margin: 0,
    padding: 0,
    whiteSpace: "pre-line"
  }
}));

export default function ProjectMapCornwall(props) {
  mapboxgl.accessToken = 'pk.eyJ1IjoiZ2FpYXRlY2hub2xvZ2llcyIsImEiOiJjbTBmZHdzMHUwdTBsMmtzMWUzeDI4dDJ5In0.urhWTkJYeGEhMlsA-FCf1A';

  const investmentProjectService = new InvestmentProjectService();
  const [zoom, setZoom] = useState(6);  // Track zoom level
  const [hoveredRegionId, setHoveredRegionId] = useState(null);
  const mapContainer = useRef(null);  // Reference to the map container

  useEffect(() => {
    // Initialize Mapbox map with outdoors style
    const map = new mapboxgl.Map({
      container: mapContainer.current,  // DOM element to contain the map
      style: "mapbox://styles/mapbox/outdoors-v11", // Outdoors style
      center: [-4.2026, 56.4907],  // Center map on Cornwall (Longitude: -5.1, Latitude: 50.5)
      zoom: zoom,  // Set initial zoom level
    });

    // Add GeoJSON data as a layer
    map.on('load', () => {
      map.addSource('cornwall-data', {
        type: 'geojson',
        data: data  // GeoJSON data
      });

      map.addLayer({
        id: 'cornwall-regions',
        type: 'fill',
        source: 'cornwall-data',
        paint: {
          "fill-color": "#005EB8",
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.5,
            0.3
          ]
        },
      });

      // Add an outline layer for the regions
      map.addLayer({
        id: "regions-outline",
        type: "line",
        source: "cornwall-data",
        paint: {
          "line-color": "#005EB8",
          "line-width": 1,
        },
      });

      // Add hover effect (on mouse hover over regions)
      map.on('mousemove', 'cornwall-regions', (e) => {
        const regionName = e.features[0].properties.NAME;
        map.getCanvas().style.cursor = 'pointer';
        // Optionally, show a tooltip with the region name
        console.log(regionName);  // Handle tooltip content
      });

      map.on('mouseleave', 'cornwall-regions', () => {
        map.getCanvas().style.cursor = '';
      });

      // Handle click events on regions
      map.on('click', 'cornwall-regions', (e) => {
        const geoRegion = e.features[0].properties.NAME;
        props.handleMapRegionClick(geoRegion);
      });
    });

    // Sync Mapbox zoom with React state
    map.on('zoom', () => {
      setZoom(map.getZoom());  // Update the zoom state with Mapbox zoom level
    });

    // Optional: Add navigation control for zoom and rotation
    // map.addControl(new mapboxgl.NavigationControl(), "top-left");

    return () => map.remove();  // Cleanup on unmount
  }, [zoom]);  // Re-run effect if zoom state changes

  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      {/* Mapbox container for the map */}
      <div ref={mapContainer} style={{ position: "absolute", width: "100%", height: "100%" }}></div>
    </div>
  );
}
