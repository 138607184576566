import { useContext, useEffect, useState } from 'react';
import { HubDetailsContext } from '../../../../shared/context/hub-details';
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditIcon from '@mui/icons-material/Edit';
import AdminHubOrganisationSettingsService from '../../../services/admin-hub-organisation-settings-service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';


const OrganisationSettingsForm = () => {

  const adminHubOrganisationSettingsService = new AdminHubOrganisationSettingsService();
  const hub = useContext(HubDetailsContext);
  const [isEditing, setIsEditing] = useState(false);
  const [organisationSettings, setOrganisationSettings] = useState({
    organisation_name: '',
    support_email_address: '',
    address_line_1: '',
    address_line_2: '',
    town: '',
    postcode: '',
    privacy_policy_link: '',
    terms_and_conditions_link: '',
    bng_awaiting_approval_message: '',
    investment_awaiting_approval_message: '',
    meta_title: '',
    meta_description: ''
  });

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{
        'color': []
      }, { 'background': [] }],
      ['link', 'image', 'video'],
      [{ 'align': [] }],

    ],
  };

  // const formats = [
  //   'header', 'font',
  //   'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background', 'align',
  //   'super', 'subscript',
  //   'list', 'bullet', 'indent',
  //   'link', 'image', 'video'
  // ];

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "background",
    "color",
    "code",
    "align",
    "script",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "size"
  ];


  useEffect(() => {
    // Set organisation settings.
    if (hub.hubDetails.organisation_settings) {
      const organisationSettings = {
        organisation_name: hub.hubDetails.organisation_settings.organisation_name,
        support_email_address: hub.hubDetails.organisation_settings.support_email_address,
        address_line_1: hub.hubDetails.organisation_settings.address_line_1,
        address_line_2: hub.hubDetails.organisation_settings.address_line_2,
        town: hub.hubDetails.organisation_settings.town,
        postcode: hub.hubDetails.organisation_settings.postcode,
        privacy_policy_link: hub.hubDetails.organisation_settings.privacy_policy_link,
        terms_and_conditions_link: hub.hubDetails.organisation_settings.terms_and_conditions_link,
        bng_awaiting_approval_message: hub.hubDetails.organisation_settings.bng_awaiting_approval_message,
        investment_awaiting_approval_message: hub.hubDetails.organisation_settings.investment_awaiting_approval_message,
        meta_title: hub.hubDetails.organisation_settings.meta_title,
        meta_description: hub.hubDetails.organisation_settings.meta_description,
      };

      setOrganisationSettings(organisationSettings);
    }
  }, [hub]);

  // Set the required branding config data.
  const setOrganisationSettingsData = (formControl, value) => {
    const settings = organisationSettings;
    settings[formControl] = value;

    setOrganisationSettings({ ...settings });
  };

  const toggleEditing = () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      adminHubOrganisationSettingsService.updateSettings(organisationSettings)
        .then(() => {
          hub.setChange(hub.change + 1);
          setIsEditing(false);
        });
    }
  };

  return (<>
    {organisationSettings && <>

      <Stack direction='row' sx={{ width: '100%', justifyContent: 'flex-end', mb: 1, pl: '56px', pr: '56px' }}>
        <Button
          variant={isEditing ? 'contained' : 'outlined'}
          disableElevation
          className="removeUppercase"
          startIcon={isEditing ? <SaveOutlinedIcon /> : <EditIcon />}
          onClick={() => toggleEditing()}
          sx={{ minWidth: '90px' }}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </Stack>

      <Grid container spacing={4} sx={{ pl: '56px', pr: '56px', pt: '10px', mb: 4 }}>
        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Organisation display name:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Organisation display name'
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('organisation_name', e.target.value)}
          value={organisationSettings.organisation_name || ''}
          sx={{ width: '100%' }}
        />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Site meta_title:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='meta_title'
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('meta_title', e.target.value)}
          value={organisationSettings.meta_title || ''}
          sx={{ width: '100%' }}
        />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Site meta_description:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='meta_description'
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('meta_description', e.target.value)}
          value={organisationSettings.meta_description || ''}
          sx={{ width: '100%' }}
        />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Support email address:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Support email address'
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('support_email_address', e.target.value)}
          value={organisationSettings.support_email_address || ''}
          sx={{ width: '100%' }}
        />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Business address:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Address line 1'
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('address_line_1', e.target.value)}
          value={organisationSettings.address_line_1 || ''}
          sx={{ width: '100%', mb: 1 }}
        />

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Address line 2'
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('address_line_2', e.target.value)}
          value={organisationSettings.address_line_2 || ''}
          sx={{ width: '100%', mb: 1 }}
        />

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Town'
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('town', e.target.value)}
          value={organisationSettings.town || ''}
          sx={{ width: '100%', mb: 1 }}
        />

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Postcode'
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('postcode', e.target.value)}
          value={organisationSettings.postcode || ''}
          sx={{ width: '100%' }}
        />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2, mt: 2 }}>
          Link to privacy policy:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          placeholder='Link to privacy policy'
          size="small"
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('privacy_policy_link', e.target.value)}
          value={organisationSettings.privacy_policy_link || ''}
          sx={{ width: '100%' }}
        />

        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
          Link to terms & conditions:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Link to terms and conditions'
          disabled={!isEditing}
          onChange={e => setOrganisationSettingsData('terms_and_conditions_link', e.target.value)}
          value={organisationSettings.terms_and_conditions_link || ''}
          sx={{ width: '100%' }}
        />

        {organisationSettings && <Box sx={{ mt: 3, mb: 5, width: '100%' }}>

          <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
            Custom awaiting approval message - Unit based project (BNG, Carbon, etc)
          </Typography>

          {!isEditing && <Box sx={{ maxWidth: '100%', border: '1px solid grey', padding: 2, borderRadius: 1, color: 'gray' }}>
            {organisationSettings.bng_awaiting_approval_message && parse(organisationSettings.bng_awaiting_approval_message)}
          </Box>}

          {isEditing && <ReactQuill
            theme="snow"
            formats={formats}
            modules={modules}
            style={{ height: '400px', width: '100%', marginBottom: '24px' }}
            placeholder='Please enter the article content'
            value={organisationSettings.bng_awaiting_approval_message || ''}
            onChange={e => setOrganisationSettingsData('bng_awaiting_approval_message', e)}
          />}

        </Box>}

        {organisationSettings && <Box sx={{ mt: 3, mb: 5, width: '100%' }}>
          <Typography variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 2 }}>
            Custom awaiting approval message - Investment projects
          </Typography>

          {!isEditing && <Box sx={{ maxWidth: '100%', border: '1px solid grey', padding: 2, borderRadius: 1, color: 'gray' }}>
            {organisationSettings.investment_awaiting_approval_message && parse(organisationSettings.investment_awaiting_approval_message)}
          </Box>}


          {isEditing && <ReactQuill
            theme="snow"
            formats={formats}
            modules={modules}
            style={{ height: '400px', marginBottom: '24px' }}
            placeholder='Please enter the article content'
            value={organisationSettings.investment_awaiting_approval_message || ''}
            onChange={e => setOrganisationSettingsData('investment_awaiting_approval_message', e)}
          />}

        </Box>}

      </Grid>
    </>}
  </>);
};

export default OrganisationSettingsForm;