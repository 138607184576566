import { useContext, useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControl, Grid, Stack, TextField, ToggleButton, Typography } from '@mui/material';
import CognitoUserAuthenticationService from '../../services/cognito-user-authentication-service';
import { CompanyDisplayName } from '../presentation/company-display-name';
import { TermsAndConditionsLink } from '../presentation/terms-and-conditions-link';
import { HubDetailsContext } from '../../context/hub-details';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';

export const RegistrationForm = (props) => {

  const [errors, setErrors] = useState([]);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [isLincHub, setIsLincHub] = useState(false);

  const hubDetailsContext = useContext(HubDetailsContext);
  const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance(hubDetailsContext.hubDetails?.cookie_domain);


  useEffect(() => {
    if (hubDetailsContext.hubDetails) {
      setIsLincHub(hubDetailsContext.hubDetails.owner_code === 'cornwall-council-app');
    }
  }, [hubDetailsContext]);

  const handleDetailsChange = (event) => {
    setErrors([]);
    const value = event.target.name === 'organisation' || event.target.name === 'firstname' || event.target.name === 'lastname' ? event.target.value : event.target.value.trim();
    props.setDetails({ ...props.details, [event.target.name]: value });
  };

  const handleSubmit = () => {
    const valid = checkForm();

    if (valid) {
      cognitoUserAuthenticationService.registerUser(props.details)
        .then(response => {
          segmentIdentify();

          props.setShowAuthCode(true);
        })
        .catch(err => {
          switch (err.code) {
            case 'UsernameExistsException':
              props.setErrors(['An account with this email address already exists. You can login and reset your password by using the button at the bottom of the page.']);
              break;
            case 'InvalidPasswordException':
              props.setErrors([err.message]);
              break;
            case 'InvalidParameterException':
              props.setErrors([err.message]);
              break;
            default:
              console.log(err);
          }
        });
    }
  };

  const checkForm = () => {
    setErrors([]);

    const tempErrors = [];
    if (!props.details.role) {
      tempErrors.push('Please enter your role.');
    }
    if (!props.details.email) {
      tempErrors.push('Please enter your email.');
    };
    if (!props.details.firstname) {
      tempErrors.push('Please enter your name.');
    };
    if (!props.details.lastname) {
      tempErrors.push('Please enter your surname.');
    };
    if (!props.details.organisation) {
      tempErrors.push('Please enter your organisation name.');
    };
    if (!props.details.password) {
      tempErrors.push('Please enter your password.');
    }
    if (!props.details.passwordConfirmation) {
      tempErrors.push('Please enter a password confirmation.');
    }
    if (props.details.password && props.details.passwordConfirmation && props.details.password !== props.details.passwordConfirmation) {
      tempErrors.push('These passwords do not match. Please check them and try again');
    }
    if (!termsAndConditions) {
      tempErrors.push('You must accept the terms and conditions to continue.');
    };

    setErrors(tempErrors);
    props.setErrors(tempErrors);

    return tempErrors.length === 0;
  };

  const handleLoginClick = () => {
    props.setErrors([]);
    props.toggleFormType();
  };

  const segmentIdentify = async () => {
    window.analytics.identify(props.details.email, {
      email: props.details.email,
      name: props.details.firstname + ' ' + props.details.lastname,
      first_name: props.details.firstname,
      last_name: props.details.lastname,
      organisation: props.details.organisation,
      role: props.details.role
    }, {
      integrations: {
        Intercom: {
          user_hash: "crypto.createHmac('sha256', '7tleEEJ4NRaLWRNGURs0Oz3m2y-g7t0hYEFMysGi').update(user.id).digest('hex')"
        }
      }
    })
      .then(response => {
        segmentTrack();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const segmentTrack = async () => {
    window.analytics.track('user_sign_up', {
      category: 'user_sign_up',
      email: props.details.email,
      name: props.details.firstname + ' ' + props.details.lastname,
      first_name: props.details.firstname,
      last_name: props.details.lastname,
      organisation: props.details.organisation,
      role: props.details.role
    })
      .catch(err => {
        console.log(err);
      });
  };

  return (<>
    {!props.hasInputUserDetails && <Box sx={{ pt: 1 }}>
      {!props.roleAutoProvided && <>
        <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
          <ToggleButton
            value='Buyer'
            fullWidth={true}
            selected={
              props.details.role === 'Buyer'
            }
            className='removeUppercase'
            error={errors.length > 0 && !props.details.role}
            name='role'
            sx={{ display: "flex", justifyContent: "flex-start" }}
            onChange={e => handleDetailsChange(e)}
          >
            {props.details.role === 'Buyer'
              ? <RadioButtonChecked fontSize="small" sx={{ mr: 1 }} />
              : <RadioButtonUnchecked fontSize="small" sx={{ mr: 1 }} />
            }
            {isLincHub ? 'I am a buyer' : 'I want to purchase biodiversity units'}
          </ToggleButton>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ mb: 4 }}>
          <ToggleButton
            value='Seller'
            fullWidth={true}
            error={true}
            selected={
              props.details.role === 'Seller'
            }
            className='removeUppercase'
            sx={{ display: "flex", justifyContent: "flex-start" }}
            name='role'
            onChange={e => handleDetailsChange(e)}
          >
            {props.details.role === 'Seller'
              ? <RadioButtonChecked fontSize="small" sx={{ mr: 1 }} />
              : <RadioButtonUnchecked fontSize="small" sx={{ mr: 1 }} />
            }
            {isLincHub ? 'I am a seller' : ' I want to sell biodiversity units'}
          </ToggleButton>
        </Grid>
      </>}

      <FormControl sx={{ mb: 3, width: '100%' }}>
        <TextField
          className="formField"
          label="Email"
          name='email'
          error={errors.length > 0 && !props.details.email}
          value={props.details.email}
          onChange={e => handleDetailsChange(e)}
        />
      </FormControl>

      {isLincHub && <FormControl sx={{ mb: 3, width: '100%' }}>
        <TextField
          className="formField"
          type="number"
          label="Phone number"
          name='phone'
          value={props.details.phone}
          onChange={e => handleDetailsChange(e)}
          InputProps={{
            type: "number",
            sx: {
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none'
              },
              '& input[type=number]': {
                MozAppearance: 'textfield'
              },
            }
          }}
        />
      </FormControl>}


    </Box>}
    {!props.hasInputUserDetails && <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}
    >
      <FormControl sx={{ mr: 1, width: '100%' }}>
        <TextField
          className="formField"
          label="First name"
          error={errors.length > 0 && !props.details.firstname}
          name='firstname'
          value={props.details.firstname}
          onChange={e => handleDetailsChange(e)}
        />
      </FormControl>
      <FormControl sx={{ ml: 1, width: '100%' }}>
        <TextField
          className="formField"
          label="Last name"
          error={errors.length > 0 && !props.details.lastname}
          name='lastname'
          value={props.details.lastname}
          onChange={e => handleDetailsChange(e)}
        />
      </FormControl>
    </Box>}
    <Box>
      {!props.hasInputUserDetails && <FormControl sx={{ mb: 3, width: '100%' }}>
        <TextField
          className="formField"
          label="Organisation or Business Name"
          name='organisation'
          error={errors.length > 0 && !props.details.organisation}
          value={props.details.organisation}
          onChange={e => handleDetailsChange(e)}
        />
      </FormControl>}
      <FormControl sx={{ mb: 3, width: '100%' }}>
        <TextField
          className="formField"
          label="Password"
          type='password'
          error={errors.length > 0 && (!props.details.password || (props.details.password && (props.details.password !== props.details.passwordConfirmation)))}
          name='password'
          value={props.details.password}
          onChange={e => handleDetailsChange(e)}
        />
      </FormControl>
      <FormControl sx={{ mb: 3, width: '100%' }}>
        <TextField
          className="formField"
          label="Password confirmation"
          name='passwordConfirmation'
          type='password'
          error={errors.length > 0 && (!props.details.passwordConfirmation || (props.details.passwordConfirmation && (props.details.password !== props.details.passwordConfirmation)))}
          value={props.details.passwordConfirmation}
          onChange={e => handleDetailsChange(e)}
        />
      </FormControl>

      <Stack direction='row' alignItems='flex-start'>
        <Checkbox
          name='resource_is_external'
          checked={termsAndConditions || false}
          onChange={e => setTermsAndConditions(e.target.checked)}
        />

        {!isLincHub && <Typography variant="p" color={errors.length > 0 && !termsAndConditions ? 'error' : 'text.primary'} sx={{ mt: 1 }}>
          I accept the <CompanyDisplayName /> <TermsAndConditionsLink />.
        </Typography>}

        {isLincHub && <Typography variant="p" color={errors.length > 0 && !termsAndConditions ? 'error' : 'text.primary'} sx={{ mt: 1 }}>
          By ticking this box you confirm that you have read and accept the Terms
          and Conditions set out in our <TermsAndConditionsLink isLinc={true} /> document.
        </Typography>}
      </Stack>

    </Box>
    <Button
      variant="contained"
      color="primary"
      size="large"
      disableElevation
      onClick={() => handleSubmit()}
      className='removeUppercase'
      fullWidth
      sx={{ textAlign: 'center', mt: '26px' }}
    >
      Sign up
    </Button>
    <Button
      color="primary"
      variant="outlined"
      size="large"
      disableElevation
      //onClick={() => props.toggleFormType()}
      onClickCapture={() => handleLoginClick()}
      className='removeUppercase'
      fullWidth
      sx={{ textAlign: 'center', mt: 2 }}
    >
      Already signed up? Log in here
    </Button>
  </>
  );
};