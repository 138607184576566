import { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import CognitoUserAuthenticationService from '../../services/cognito-user-authentication-service';
import { Link, useNavigate } from 'react-router-dom';
import { UserDetailsContext } from '../../context/user-details';
import { CompanyDisplayName } from '../presentation/company-display-name';
import { HubDetailsContext } from '../../context/hub-details';

export const LoginForm = (props) => {

  const hubDetailsContext = useContext(HubDetailsContext);
  const [authenticationErrorMessage, setAuthenticationErrorMessage] = useState();
  const cognitoUserAuthenticationService = CognitoUserAuthenticationService.getInstance(hubDetailsContext.hubDetails?.cookie_domain);
  const userDetailsContext = useContext(UserDetailsContext);
  const navigate = useNavigate();

  const handleUpdate = (event) => {
    props.setDetails({ ...props.details, [event.target.name]: event.target.value.trim() });
  };

  const handleLogin = () => {
    cognitoUserAuthenticationService.loginUser(props.details.email, props.details.password)
      .then(() => {
        userDetailsContext.forceUpdateUserDetails()
          .then(() => {
            if (props.redirectOnLogin) {
              redirectOnLogin();
            } else {
              props.closeDialog();

            }
          });
      })
      .catch(err => {
        console.log(err);
        if (err.code?.indexOf('UserNotConfirmedException') !== -1) {
          props.setShowAuthCode(true);
        } else {
          setAuthenticationErrorMessage(err.message);
        }
      });
  };

  const redirectOnLogin = () => {
    let from = '/login';
    userDetailsContext.forceUpdateUserDetails()
      .then((updatedDetails) => {
        const forwardLink = sessionStorage.getItem('forward_link');
        const searchParams = sessionStorage.getItem('search');
        sessionStorage.removeItem('forward_link');
        sessionStorage.removeItem('search');

        if (updatedDetails.roles.includes('SELLER') || updatedDetails.roles.includes('ECOLOGIST')) {

          const dashboardPath = updatedDetails?.isAdmin ? '/admin/dashboard' : '/dashboard';
          from = dashboardPath;
          from = forwardLink ? forwardLink : dashboardPath;
          segmentIdentify(updatedDetails.email);
          // segmentTrack(updatedDetails.email);
          navigate({ pathname: from, search: searchParams, replace: true });
        } else if (updatedDetails.roles.includes('BUYER')) {
          from = forwardLink ? forwardLink : `${hubDetailsContext.hubDetails.home_page_url}`;
          segmentIdentify(updatedDetails.email);
          // segmentTrack(updatedDetails.email);
          navigate({ pathname: from, search: searchParams, replace: true });
        }
      });
  };

  const segmentIdentify = async (email) => {
    console.log(window.analytics);
    window.analytics.identify(email, {
      email: email
    }, {
      integrations: {
        Intercom: {
          user_hash: "crypto.createHmac('sha256', '7tleEEJ4NRaLWRNGURs0Oz3m2y-g7t0hYEFMysGi').update(user.id).digest('hex')"
        }
      }
    })
      .then(response => {
        segmentTrack(email);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const segmentTrack = async (email) => {
    window.analytics.track('user_login', {
      category: 'user_login',
      email: email
    })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <Box sx={{
      width: '100%',
      mt: 1
    }}>
      <Grid container spacing={2}>
        {authenticationErrorMessage && (
          <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px", mb: "10px" }}>
            <Alert severity="error">{authenticationErrorMessage}</Alert>
          </Grid>
        )}
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            width: '100%', pl: 2
          }}
        >
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label="Email address"
              id="email"
              name='email'
              placeholder="Email address"
              onChange={handleUpdate}
              value={props.details.email}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <TextField
              fullWidth
              className="formField"
              label="Password"
              id="password"
              name='password'
              placeholder="Password"
              onChange={handleUpdate}
              value={props.details.password}
              type="password"
            />
          </FormControl>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px", mb: "10px" }}>
            <Link to="/reset" style={{ float: 'right', color: '#70942c', backgroundColor: 'primary', textDecoration: 'underline', fontFamily: 'EffraMd,Effra2,Effra,Roboto,"Helvetica Neue",Arial,sans-serif' }}>Forgot password?</Link>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              onClick={handleLogin}
              className='removeUppercase'
              fullWidth
              sx={{ textAlign: 'center', mt: '26px' }}
            >
              Sign in
            </Button>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              disableElevation
              className='removeUppercase'
              onClick={() => props.toggleFormType()}
              fullWidth
              sx={{ textAlign: 'center', mt: 2 }}
            >
              New to <CompanyDisplayName />? Sign up for free
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};