import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { NumericFormat } from 'react-number-format';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import SpaIcon from '@mui/icons-material/Spa';
import WaterIcon from '@mui/icons-material/Water';
import GrassIcon from '@mui/icons-material/Grass';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Autocomplete from '@mui/material/Autocomplete';
import { nationalCharacterAreasAutocomplete } from '../../shared/data/national-character-areas';
import { Alert, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import SellerHabitatDialog from '../components/dialogs/habitat-dialog';
import ProjectService from '../../shared/services/project-service.service';
import SellerProjectService from '../services/project-service';
import { ImageFileUpload } from '../../shared/components/image-file-upload';
import QuestionForm from '../../shared/components/question-form';
import UnitProjectDataInputForm from '../components/unit-project-input-data-form';
import IconPicker from '../../shared/components/icon-picker';
import { GaiaTextField } from '../../shared/components/form-elements/text-field';
import { HubDetailsContext } from '../../shared/context/hub-details';
import LocalPlanningAuthorityService from '../../shared/services/local-planning-authority.service';
import DialogCreateIssuance from '../components/dialogs/dialog-create-issuance';
import { Add } from '@mui/icons-material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CreateProject = (props) => {
  const projectService = new ProjectService();
  const sellerProjectService = new SellerProjectService();
  const navigate = useNavigate();
  const params = useParams();
  const hubDetailsContext = useContext(HubDetailsContext);
  const localPlanningAuthorityService = LocalPlanningAuthorityService.getInstance();

  const [habitatFormActive, setHabitatFormActive] = useState(false);
  const [errors, setErrors] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [createdProject, setCreatedProject] = useState();
  const [selectedHabitatTypes, setSelectedHabitatTypes] = useState([]);
  const [currentHabitatIndex, setCurrentHabitatIndex] = useState();
  const [fileData, setFileData] = useState([]);
  const [primaryImageIndex, setPrimaryImageIndex] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [answerFormId, setAnswerFormId] = useState();
  const [projectId, setProjectId] = useState();
  const [unitProjectTypes, setUnitProjectTypes] = useState([]);
  const [forceForwardRoute, setForceForwardRoute] = useState();
  const [lpas, setLpas] = useState();
  const [issuanceDialogActive, setIssuanceDialogActive] = useState(false);


  useEffect(() => {
    if (params.projectId) {
      setProjectId(params.projectId);
      sellerProjectService.getProject(params.projectId)
        .then(response => {
          if (response.answer_forms.length > 0) {

            if (params.answerFormId) {
              setAnswerFormId(params.answerFormId);
              setForceForwardRoute(`/project/${params.projectId}`);
            } else {
              setAnswerFormId(response.answer_forms.find(form => !form.previous_answer_form_id).id);
            }
            setCreatedProject(response);
          }
        });
    } else {
      createProject();
    }

    localPlanningAuthorityService.getLpas()
      .then(response => {
        setLpas(response);
      });

    sellerProjectService.getUnitProjectTypes()
      .then(response => {
        setUnitProjectTypes(response);
      });
    window.analytics.page();
  }, []);

  // Create the initial project object and add arrays for the specific areas.
  function createProject(p_summary = '', p_description = '', p_location = '', primary_image_name = '', p_local_planning_authority = '', p_national_character_area = '', bng_registration_number = '') {
    const project = {
      p_summary: p_summary,
      p_description: p_description,
      p_location: p_location,
      p_local_planning_authority: hubDetailsContext.hubDetails.id === 4 ? 'Cornwall' : p_local_planning_authority,
      p_national_character_area: p_national_character_area,
      bng_registration_number: bng_registration_number,
      project_habitats: [],
      unit_project: undefined,
      images: [],
      is_unit_project: false,
      use_slider_image: false
    };

    setCreatedProject(project);

  };

  // Move to the next section.
  const handleNext = () => {
    setIsUploading(false);
    setErrors([]);

    if (activeStep === 1) {
      if (selectedHabitatTypes.length > 0) {
        const project = createdProject;
        const habitats = project.project_habitats.filter(habitat => selectedHabitatTypes.map(type => type.toLowerCase()).includes(habitat.biodiversityType));
        project.project_habitats = habitats;
        setCreatedProject({ ...project });
        setActiveStep(activeStep + 1);
        window.scrollTo(0, 0);
      } else {
        //setErrors(['Please select at least on habitat type.'])
        setActiveStep(activeStep + 1);
        window.scrollTo(0, 0);
      }
    }

    if (activeStep === 2) {
      if (createdProject.is_unit_project) {
        if (!createdProject.unit_project.price_unit || !createdProject.unit_project.unit_project_type) {
          setErrors(['Please enter the required information below.']);
        } else {
          setErrors([]);
          setActiveStep(activeStep + 1);
          window.scrollTo(0, 0);
        }
      } else {
        setActiveStep(activeStep + 1);
        window.scrollTo(0, 0);
      }
    }

    if (activeStep === 3) {
      const requireNca = lpas.find(lpa => lpa.lpa_name === createdProject.p_local_planning_authority)?.country === 'england';
      if (createdProject.p_local_planning_authority && (createdProject.p_national_character_area || !requireNca)) {
        setActiveStep(activeStep + 1);
        window.scrollTo(0, 0);
      } else {
        setErrors(['Please enter the required information below.']);
      }
    }

    if (activeStep === 4) {
      if (createdProject.p_summary) {
        setIsUploading(true);
        setErrors([]);

        // Ensure only habitats that have been saved are submitted to the BE.
        const project = createdProject;
        const savedHabitats = createdProject.project_habitats.filter(habitat => habitat.saved);
        project.project_habitats = savedHabitats;
        setCreatedProject({ ...project });

        const form = new FormData();

        for (let i = 0; i < fileData.length; i++) {
          form.append("files", fileData[i]);
        }

        form.append('project', JSON.stringify(createdProject));
        form.append('primary_image_index', primaryImageIndex);

        projectService.createNewProject(form)
          .then(response => {
            setIsUploading(false);

            if (response.project.answer_forms) {

              setProjectId(response.project.p_id);
              navigate(`/project/questions/${response.project.p_id}`, { shallow: true });
              setAnswerFormId(response.project.answer_forms.find(form => !form.previous_answer_form_id).id);

            } else {
              navigate(`/project/${response.project.p_id}`);
            }
          })
          .catch(err => {
            setIsUploading(false);
            console.log(err);
          });
      } else {
        setErrors(['Please enter a project summary below.']);
      }
    }
  };

  // Move back one section.
  const handleBack = () => {
    if (createdProject.is_unit_project && activeStep === 2) {
      setActiveStep(activeStep - 2);
      setCreatedProject({ ...createdProject, unit_project: undefined });
    } else {
      setActiveStep(activeStep - 1);
    }

  };

  // Add or remove selected habitat types in state so the correct UI can be shown on the first tab.
  const handleHabitatTypes = (event, habitatType) => {
    setErrors([]);
    if (!selectedHabitatTypes.includes(habitatType)) {
      setSelectedHabitatTypes([...selectedHabitatTypes, habitatType]);
    } else {
      const types = selectedHabitatTypes.filter(type => type !== habitatType);
      setSelectedHabitatTypes(types);
    }
  };

  const handleProjectType = (event, projectType) => {
    console.log(projectType);
    if (projectType.is_unit_project) {
      setCreatedProject({
        ...createdProject,
        is_unit_project: true,
        project_habitats: [],
        unit_project: {
          unit_project_type: projectType.code,
          unit_project_name: projectType.name,
          hide_price: undefined,
          price: undefined,
          price_unit: undefined
        }
      });
      setSelectedHabitatTypes([]);
      setActiveStep(activeStep + 2);
    } else {
      setCreatedProject({ ...createdProject, is_unit_project: false });
      setActiveStep(activeStep + 1);
    }
  };

  const handleUpdateUnitProject = (event, isCheckbox) => {
    const unitProject = createdProject.unit_project;

    if (isCheckbox) {
      unitProject.hide_price = event.target.checked;
      setCreatedProject({ ...createdProject, unit_project: unitProject });
    } else {
      unitProject[event.target.name] = event.target.value;
      setCreatedProject({ ...createdProject, unit_project: unitProject });
    }
  };

  // Show the habitats dialog. If the user is adding a habitat create the object and add it to the project.
  const showHabitatForm = (index, habitatType, isEdit = false) => {

    if (isEdit) {
      setCurrentHabitatIndex(index);
      setHabitatFormActive(true);
    } else {
      const createdHabitat = {
        biodiversityType: habitatType,
        broadHabitat: '',
        habitat: '',
        area: '',
        units: '',
        totalPrice: '',
        hidePrice: false,
        saved: false
      };

      const project = createdProject;
      project.project_habitats.push(createdHabitat);

      setCreatedProject({ ...project });
      setCurrentHabitatIndex(createdProject.project_habitats.length - 1);
      setHabitatFormActive(true);
    }
  };

  // Handle closing the habitat dialog.
  function handleHabitatDialogClose(index, shouldSave) {
    const project = createdProject;
    if (shouldSave) {
      project.project_habitats[index].saved = true;
      setCreatedProject({ ...project });
    }
    setHabitatFormActive(false);
    setCurrentHabitatIndex(undefined);
  }

  // Set the habitat data for the required index and property.
  function setHabitatData(index, formControl, value) {
    const project = createdProject;
    project.project_habitats[index][formControl] = value;

    setCreatedProject({ ...project });
  };

  // Set the required project data.
  function setProjectData(formControl, value) {
    console.log(formControl, value);
    setErrors([]);
    const project = createdProject;
    project[formControl] = value;

    setCreatedProject({ ...project });
  }

  // Handle deleteing a habitat if the user clicks the delete button.
  const handleHabitatRowDelete = (index) => {
    const project = createdProject;
    project.project_habitats.splice(index, 1);
    setCreatedProject({ ...project });
  };

  function getValidNationalCharchterAreas() {
    const cornwallNcas = ['Cornish Killas', 'Bodmin Moor', 'Hensbarrow', 'Carnmenellis', 'West Penwith', 'The Lizard', 'The Culm'];
    if (hubDetailsContext.hubDetails.id === 4) {
      return nationalCharacterAreasAutocomplete.filter(value => cornwallNcas.includes(value.label));
    } else {
      return nationalCharacterAreasAutocomplete;
    }
  }

  function getValidLocalAuthority() {
    const cornwallLpas = ['Cornwall'];
    if (hubDetailsContext.hubDetails.id === 4) {
      return lpas?.filter(value => cornwallLpas.includes(value.lpa_name));
    } else {
      return lpas;
    }
  }

  const handleAddIssuance = (issuance) => {
    let issuances = createdProject.issuances;
    const project = createdProject;
    if (!issuances) {
      project.issuances = [issuance];
    } else {
      project.issuances.push(issuance);
    }
    setIssuanceDialogActive(false);
    setCreatedProject(project);
  };

  const handleDeleteIssuance = (providedIndex) => {
    const filtered = createdProject.issuances.filter((issuance, index) => index !== providedIndex);
    setCreatedProject({ ...createdProject, issuances: filtered });
  };

  return (
    <>
      <Toolbar />
      <Container maxWidth="mdxl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: '100%',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '10px'
              }}
              elevation={0}
            >
              <Box sx={{ width: '100%', p: '24px' }}>
                {answerFormId && projectId && <QuestionForm answerFormId={answerFormId}
                  forwardRoute={`/project/${projectId}`}
                  forceForwardRoute={forceForwardRoute}
                  haveProgressBar={true} />}
                {!answerFormId && <>
                  <Grid container spacing={2} sx={{ pl: '24px', pr: '24px' }}>
                    <Grid item xs={12} md={12} lg={12}>
                      {activeStep !== 0 && (
                        <Link
                          component="button"
                          variant="body2"
                          onClick={handleBack}
                          disabled={activeStep === 0 || isUploading}
                          underline='hover'
                          sx={{ ml: '-7px' }}
                          className='removeUppercase'
                        >
                          <Grid container direction="row" alignItems="center">
                            <KeyboardArrowLeft />
                            <Typography variant="button" display="block" className='removeUppercase'>
                              Back
                            </Typography>
                          </Grid>
                        </Link>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="h6">
                        {activeStep + 1} of 5
                      </Typography>
                    </Grid>
                    {errors.length > 0 && <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px", mb: "10px" }}>
                      <Alert severity="error">{errors[0]}</Alert>
                    </Grid>}
                    <Grid item xs={12} md={12} lg={12}>
                      <MobileStepper
                        variant="progress"
                        steps={5}
                        position="static"
                        activeStep={activeStep}
                        className="fullWidthStepper"
                        sx={{ flexGrow: 1, pl: 0, width: '100%' }}
                      />
                    </Grid>
                  </Grid>

                  <TabPanel value={activeStep} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5">
                          Which type of project are you looking to create?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Select the correct project type below.
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={6} md={4} lg={4}>
                        <ToggleButton
                          value='Biodiversity net gain (BNG)'
                          fullWidth={true}
                          className='square removeUppercase'
                          onChange={handleProjectType}
                          disableRipple
                        >
                          <Stack spacing={2} alignItems="center">
                            <SpaIcon fontSize="large" />
                            <Typography variant="body2" color="text.secondary">
                              BNG
                            </Typography>
                            <CheckCircleOutlineRoundedIcon fontSize="small" />
                          </Stack>
                        </ToggleButton>
                      </Grid> */}

                      {unitProjectTypes && unitProjectTypes.map((projectType, index) => {
                        return <Grid key={index} item xs={6} md={4} lg={4}>
                          <ToggleButton
                            value={projectType}
                            fullWidth={true}
                            className='square removeUppercase'
                            onChange={handleProjectType}
                            disableRipple
                            disabled={projectType.project_type_hub_owner.coming_soon}
                          >
                            <Stack spacing={2} alignItems="center">
                              <IconPicker icon={projectType.icon_code || 'trees'} size='large' />
                              {/* <GrassIcon fontSize="large" /> */}
                              <Typography variant="body2" color="text.secondary">
                                {projectType.name} {projectType.project_type_hub_owner.coming_soon && <><br></br> ({projectType.project_type_hub_owner.coming_soon_message})</>}
                              </Typography>
                              <CheckCircleOutlineRoundedIcon fontSize="small" />
                            </Stack>
                          </ToggleButton>
                        </Grid>;
                      })}
                    </Grid>
                  </TabPanel>
                  <TabPanel value={activeStep} index={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5">
                          Which type of biodiversity units are you looking to sell?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Select the types of biodiversity units that you have created on your land and are looking to sell
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={4} lg={4}>
                        <ToggleButton
                          value='Area'
                          fullWidth={true}
                          selected={
                            selectedHabitatTypes.includes('Area')
                              ? true
                              : false
                          }
                          className='square removeUppercase'
                          onChange={handleHabitatTypes}
                          disableRipple
                        >
                          <Stack spacing={2} alignItems="center">
                            <SpaIcon fontSize="large" />
                            <Typography variant="body2" color="text.secondary">
                              Area
                            </Typography>
                            {selectedHabitatTypes.includes('Area')
                              ? <CheckCircleRoundedIcon fontSize="small" />
                              : <CheckCircleOutlineRoundedIcon fontSize="small" />
                            }
                          </Stack>
                        </ToggleButton>
                      </Grid>
                      <Grid item xs={6} md={4} lg={4}>
                        <ToggleButton
                          value='Hedgerow'
                          fullWidth={true}
                          selected={
                            selectedHabitatTypes.includes('Hedgerow')
                              ? true
                              : false
                          }
                          className='square removeUppercase'
                          onChange={handleHabitatTypes}
                          disableRipple
                        >
                          <Stack spacing={2} alignItems="center">
                            <GrassIcon fontSize="large" />
                            <Typography variant="body2" color="text.secondary">
                              Hedgerow
                            </Typography>
                            {selectedHabitatTypes.includes('Hedgerow')
                              ? <CheckCircleRoundedIcon fontSize="small" />
                              : <CheckCircleOutlineRoundedIcon fontSize="small" />
                            }
                          </Stack>
                        </ToggleButton>
                      </Grid>
                      <Grid item xs={6} md={4} lg={4}>
                        <ToggleButton
                          value='Watercourse'
                          fullWidth={true}
                          selected={
                            selectedHabitatTypes.includes('Watercourse')
                              ? true
                              : false
                          }
                          className='square removeUppercase'
                          onChange={handleHabitatTypes}
                          disableRipple
                        >
                          <Stack spacing={2} alignItems="center">
                            <WaterIcon fontSize="large" />
                            <Typography variant="body2" color="text.secondary">
                              Watercourse
                            </Typography>
                            {selectedHabitatTypes.includes('Watercourse')
                              ? <CheckCircleRoundedIcon fontSize="small" />
                              : <CheckCircleOutlineRoundedIcon fontSize="small" />
                            }
                          </Stack>
                        </ToggleButton>
                      </Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleNext}
                        className='removeUppercase'
                        endIcon={<ChevronRightIcon />}
                        sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                      >
                        Next
                      </Button>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={activeStep} index={2}>
                    {!createdProject?.is_unit_project && <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5">
                          Which habitats have been created or enhanced?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Enter the habitats that have been created or enhanced, alongside the area in hectares.
                        </Typography>
                      </Grid>
                      <Stack spacing={2} direction="row" alignItems="center" sx={{ p: 2 }}>
                        {selectedHabitatTypes.includes('Area') && (
                          <Button
                            color="primary"
                            variant="outlined"
                            size="medium"
                            disableElevation
                            startIcon={<AddBoxIcon />}
                            className='removeUppercase'
                            onClick={() => showHabitatForm(null, 'area')}
                          >
                            Add Area Habitat
                          </Button>
                        )}
                        {selectedHabitatTypes.includes('Hedgerow') && (
                          <Button
                            color="primary"
                            variant="outlined"
                            size="medium"
                            disableElevation
                            startIcon={<AddBoxIcon />}
                            className='removeUppercase'
                            onClick={() => showHabitatForm(null, 'hedgerow')}
                          >
                            Add Hedgerow Habitat
                          </Button>
                        )}
                        {selectedHabitatTypes.includes('Watercourse') && (
                          <Button
                            color="primary"
                            variant="outlined"
                            size="medium"
                            disableElevation
                            startIcon={<AddBoxIcon />}
                            className='removeUppercase'
                            onClick={() => showHabitatForm(null, 'watercourse')}
                          >
                            Add Watercourse Habitat
                          </Button>
                        )}
                      </Stack>
                      {habitatFormActive && <SellerHabitatDialog
                        habitatFormActive={habitatFormActive}
                        setHabitatData={setHabitatData}
                        index={currentHabitatIndex}
                        createdProject={createdProject}
                        handleHabitatDialogClose={handleHabitatDialogClose}>
                      </SellerHabitatDialog>
                      }
                      <Grid item xs={12} md={12} lg={12}>
                        {createdProject?.project_habitats?.some(habitat => habitat.saved) && <Table size="small" sx={{ p: "16px", mt: "5px" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ pl: '0px' }}>Broad Habitat</TableCell>
                              <TableCell>Habitat</TableCell>
                              <TableCell align="right">Units</TableCell>
                              <TableCell align="right">Unit Price</TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {createdProject?.project_habitats?.filter(habitat => habitat.saved).map((createdHabitat, index) => (
                              <TableRow key={index}>
                                <TableCell sx={{ pl: '0px' }}>{createdHabitat.broadHabitat}</TableCell>
                                <TableCell>{createdHabitat.habitat}</TableCell>
                                <TableCell align="right">{createdHabitat.units}</TableCell>
                                <TableCell align="right">
                                  <NumericFormat value={createdHabitat.totalPrice / createdHabitat.units} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'£'} />
                                </TableCell>
                                <TableCell align="right">
                                  <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                                    <IconButton aria-label="edit" onClick={() => showHabitatForm(index, createdHabitat.biodiversityType, true)}>
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => handleHabitatRowDelete(index)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        }
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={handleNext}
                          className='removeUppercase'
                          endIcon={<ChevronRightIcon />}
                          sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>}

                    {createdProject?.is_unit_project && <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5">
                          Please provide details about this project.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Enter the type of project and the pricing details.
                        </Typography>
                      </Grid>
                      <Box sx={{ ml: 2, width: '100%' }}>

                        <UnitProjectDataInputForm
                          unitProject={createdProject.unit_project}
                          setUnitProject={handleUpdateUnitProject}>
                        </UnitProjectDataInputForm>

                        <DialogCreateIssuance active={issuanceDialogActive}
                          onCancel={() => setIssuanceDialogActive(false)}
                          onSubmit={handleAddIssuance}>
                        </DialogCreateIssuance>

                        <Typography variant="h5" color="text.secondary" gutterBottom sx={{ mt: 6 }}>
                          Issuances
                        </Typography>

                        <Table>
                          <TableHead>
                            <TableRow sx={{ fontWeight: 600 }}>
                              <TableCell sx={{ fontWeight: 600 }}>Vintage</TableCell>
                              <TableCell sx={{ fontWeight: 600 }}>Units</TableCell>
                              <TableCell sx={{ fontWeight: 600 }}>Unit type</TableCell>
                              <TableCell sx={{ fontWeight: 600 }}>Verifier</TableCell>
                              <TableCell sx={{ fontWeight: 600 }}>Price</TableCell>
                              <TableCell sx={{ fontWeight: 600 }}>Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {createdProject.issuances?.map((issuance, index) => {
                              return <>
                                <TableRow
                                  key={index}>
                                  <TableCell>{issuance.vintage}</TableCell>
                                  <TableCell><NumericFormat value={issuance.units} displayType={'text'} thousandSeparator={true} decimalScale={0} /></TableCell>
                                  <TableCell>{issuance.unit_type}</TableCell>
                                  <TableCell>{issuance.verifier}</TableCell>
                                  <TableCell><NumericFormat value={issuance.price} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'£'} /></TableCell>
                                  <TableCell><IconButton onClick={() => handleDeleteIssuance(index)}><DeleteIcon /></IconButton></TableCell>
                                </TableRow>
                              </>;
                            })}

                            {!createdProject.issuances?.length &&
                              <TableRow>
                                <TableCell colSpan={6}>No issuances added</TableCell>
                              </TableRow>}
                          </TableBody>
                        </Table>

                        <Box sx={{ mb: 3 }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            disableElevation
                            onClick={() => setIssuanceDialogActive(true)}
                            className='removeUppercase'
                            endIcon={<Add />}
                            sx={{ justifyContent: "flex-end", mt: 4, }}
                          >
                            Add new issuance
                          </Button>
                        </Box>

                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={handleNext}
                          className='removeUppercase'
                          endIcon={<ChevronRightIcon />}
                          sx={{ justifyContent: "flex-end", ml: "auto", mt: 4, float: "right" }}
                        >
                          Next
                        </Button>

                      </Box>
                    </Grid>}
                  </TabPanel>
                  <TabPanel value={activeStep} index={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5">
                          Where is the project located?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          This is important for any buyers, as the distance to the development site acts as a multiplier on the biodiversity units purchased.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6} sx={{ mt: "10px" }}>
                        <Autocomplete
                          disablePortal
                          id="lpa"
                          options={getValidLocalAuthority()}
                          getOptionLabel={option => option.lpa_name}
                          value={lpas?.find(lpa => lpa.lpa_name === createdProject?.p_local_planning_authority) || undefined}
                          renderInput={(params) => <TextField {...params} label="Local Planning Authority" />}
                          onInputChange={(event, newLPA) => setProjectData('p_local_planning_authority', newLPA)}
                        />
                      </Grid>
                      {hubDetailsContext?.hubDetails?.organisation_settings?.include_england_lpas && <Grid item xs={12} md={12} lg={6} sx={{ mt: "10px" }}>
                        <Autocomplete
                          disablePortal
                          id="national-character-area"
                          options={getValidNationalCharchterAreas()}
                          value={createdProject?.p_national_character_area}
                          renderInput={(params) => <TextField {...params} helperText={<p>If you're unsure about your NCA, use the Natural England map to find out - <a target="_blank" href='https://nationalcharacterareas.co.uk'>https://nationalcharacterareas.co.uk</a></p>} label="National Character Area" />}
                          onInputChange={(event, newNCA) => setProjectData('p_national_character_area', newNCA)}
                        />
                      </Grid>}
                      <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={handleNext}
                          className='removeUppercase'
                          endIcon={<ChevronRightIcon />}
                          sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={activeStep} index={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5">
                          Any additional information?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                          Provide any additional information that may be important for buyers of the biodiversity units
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <GaiaTextField
                          label="Title"
                          formControlName='p_summary'
                          id="summary"
                          onChange={setProjectData}
                          charchterCount={250}
                          minRows={1}
                          maxRows={1}
                          placeholder="Provide a title for the public listing"
                          value={createdProject?.p_summary}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <GaiaTextField
                          label="Description"
                          formControlName='p_description'
                          id="additionalInfo"
                          placeholder="Provide a description for the public listing"
                          charchterCount={4000}
                          minRows={4}
                          maxRows={20}
                          onChange={setProjectData}
                          value={createdProject?.p_description}
                        />
                      </Grid>
                      {!createdProject?.is_unit_project && <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                        <TextField
                          fullWidth
                          className="formField"
                          label="Biodiversity Gain Sites Register Number"
                          id="defraRef"
                          placeholder="Biodiversity Gain Sites Register Number"
                          onChange={e => setProjectData('bng_registration_number', e.target.value)}
                        />
                      </Grid>}
                      {hubDetailsContext.hubDetails.id === 4 && <>
                        <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                          <FormControlLabel
                            control={<Checkbox
                              onChange={e => setProjectData('fractional_units_available', !!e.target.checked)}
                            />}
                            label="Are you open selling fractional units?"
                            sx={{ color: "#00000099" }} />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                          <TextField
                            fullWidth
                            className="formField"
                            label="What is the minimum number of units that you would sell?"
                            id="minimumSaleSize"
                            placeholder="What is the minimum number of units that you would sell?"
                            onChange={e => setProjectData('minimum_sale_size', e.target.value)}
                            type="number"
                            step="any"
                            InputProps={{
                              inputProps: {
                                min: 0
                              }
                            }}
                          />
                        </Grid>
                      </>
                      }
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={createdProject?.use_slider_image}
                              onChange={e => setProjectData('use_slider_image', e.target.value)}
                            />}
                          label='Use image comparison slider'
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sx={{ mt: "10px" }}>
                        <ImageFileUpload
                          fileData={fileData}
                          setFileData={setFileData}
                          primaryImageIndex={primaryImageIndex}
                          setPrimaryImageIndex={setPrimaryImageIndex}
                          imageCountLimit={createdProject?.use_slider_image ? 2 : 10}>
                        </ImageFileUpload>
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={isUploading}
                          onClick={handleNext}
                          startIcon={isUploading ? undefined : <CheckCircleOutlineIcon />}
                          className='removeUppercase'
                          sx={{ justifyContent: "center", ml: "auto", mt: "15px", float: "right", minWidth: '180px', minHeight: '40px' }}
                        >
                          {isUploading ? <CircularProgress size={14} /> : 'Submit as draft'}
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </>}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '10px'
              }}
              elevation={0}
            >
              <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h6" color='primary'>
                  Listing Details
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableBody>

                      {createdProject?.unit_project?.unit_project_type && <TableRow
                        key={uuidv4()}
                        sx={{ borderBottom: 0 }}
                      >
                        <TableCell scope="row" sx={{ pl: '0px', borderBottom: 0, width: '50%', verticalAlign: 'top' }}>
                          <b>Project type:</b>
                        </TableCell>
                        <TableCell align="left" sx={{ pl: '0px', borderBottom: 0, width: '75%' }}>
                          {createdProject.unit_project.unit_project_name}
                        </TableCell>
                      </TableRow>}

                      {selectedHabitatTypes.length > 0 && <TableRow
                        key={uuidv4()}
                        sx={{ borderBottom: 0 }}
                      >
                        <TableCell scope="row" sx={{ pl: '0px', borderBottom: 0, width: '50%', verticalAlign: 'top' }}>
                          <b>Biodiversity Types:</b>
                        </TableCell>
                        <TableCell align="left" sx={{ pl: '0px', borderBottom: 0, width: '75%' }}>
                          {selectedHabitatTypes?.join(', ')}
                        </TableCell>
                      </TableRow>}

                      {createdProject?.project_habitats?.filter(habitat => habitat.saved).length > 0 && <TableRow key={uuidv4()} scope="row" sx={{ pl: '0px', pr: '0px', borderBottom: 0, width: '100%' }}>
                        <TableCell colSpan={2} sx={{ pl: '0px', borderBottom: 0 }}>
                          <Typography variant="subtitle1" color='primary'>
                            <b>Habitats Created/Enhanced</b>
                          </Typography>
                        </TableCell>
                      </TableRow>}
                      {createdProject?.project_habitats?.filter(h => h.saved).map((habitat, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell scope="row" sx={{ pl: '0px', borderBottom: 0, width: '50%' }}>
                              <b>{habitat.broadHabitat} - {habitat.habitat}</b>
                            </TableCell>
                            <TableCell align="left" sx={{ pl: '0px', borderBottom: 0, width: '50%', verticalAlign: 'top' }}>{habitat.units} biodiversity units</TableCell>
                          </TableRow>
                        );
                      })}

                      {(createdProject?.p_local_planning_authority || createdProject?.p_national_character_area) && <>
                        <TableRow key={uuidv4()} scope="row" sx={{ pl: '0px', pr: '0px', borderBottom: 0, width: '100%' }}>
                          <TableCell colSpan={2} sx={{ pl: '0px', borderBottom: 0 }}>
                            <Typography variant="subtitle1" color='primary'><b>Details</b></Typography>
                          </TableCell>
                        </TableRow>
                        {createdProject?.p_local_planning_authority && <TableRow>
                          <TableCell scope="row" sx={{ pl: '0px', borderBottom: 0, width: '50%', verticalAlign: 'top' }}>
                            <b>Local Planning Authority:</b>
                          </TableCell>
                          <TableCell align="left" sx={{ pl: '0px', borderBottom: 0, width: '50%', verticalAlign: 'top' }}>{createdProject?.p_local_planning_authority}</TableCell>
                        </TableRow>}

                        {createdProject?.p_national_character_area && <TableRow>
                          <TableCell scope="row" sx={{ pl: '0px', borderBottom: 0, width: '50%', verticalAlign: 'top' }}>
                            <b>National Character Area:</b>
                          </TableCell>
                          <TableCell align="left" sx={{ pl: '0px', borderBottom: 0, width: '50%', verticalAlign: 'top' }}>{createdProject?.p_national_character_area}</TableCell>
                        </TableRow>}
                      </>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CreateProject;